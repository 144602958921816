import React from "react";
import { toast } from "react-toastify";
import create from "zustand";
import * as _ from "lodash";

import {
  API_MENU_MAKER,
  DASHBOARD_CARD_EXTRA,
  DASHBOARD_CARD_MEDIUM,
  HTTP_STATUS_INTERNAL_SERVER_ERROR,
  HTTP_STATUS_NOT_FOUND,
  HTTP_STATUS_TIMEOUT,
  HTTP_STATUS_UNAUTHORIZED,
  HTTP_STATUS_UNAVAILABLE_SERVICE,
  METHOD_GET,
  SERVER_HOST,
  TOAST_CONTAINER_LAYOUT,
  TOAST_ID_SERVER_OFF,
} from "../../util/Constants";
import CustomBodyToast from "../../components/toast/CustomBodyToast";
import * as dashboardAPI from "../../solutions/home/api";
import { getLocaleText } from "../../util/UtilTraduction";
import { flattenMenuItems } from "../../util/UtilFormat";
import { isEmpty, isNil, some, has, isFunction } from "lodash";

const GET_MENU_METHOD = "/get";

export const INTRUCTIONS = {
  UP: "UP",
  DOWN: "DOWN",
  ALLUP: "ALLUP",
  ALLDOWN: "ALLDOWN",
};

function moveItem(array, from, to) {
  const arrayToReturn = [...array];
  let f = arrayToReturn.splice(from, 1)[0];
  arrayToReturn.splice(to, 0, f);
  return arrayToReturn;
}

function getNewPositionBasedOnInstruction(array, from, instruction) {
  if (isNil(array) || isEmpty(array)) {
    return;
  }

  if (instruction === INTRUCTIONS.UP) {
    if (from === 0) {
      return;
    }
    return from - 1;
  }

  if (instruction === INTRUCTIONS.DOWN) {
    if (from === array.length - 1) {
      return;
    }
    return from + 1;
  }

  if (instruction === INTRUCTIONS.ALLUP) {
    if (from === 0) {
      return;
    }
    return 0;
  }

  if (instruction === INTRUCTIONS.ALLDOWN) {
    if (from === array.length - 1) {
      return;
    }
    return array.length - 1;
  }
}

const useAccessesStore = create((set, get) => ({
  fetchingMenu: false,
  setFetchingMenu: (newState) => set({ fetchingMenu: newState }),
  menuItems: null,
  flattenedMenuItems: [],
  setMenuItems: (newMenuItems) =>
    set({
      menuItems: newMenuItems,
      flattenedMenuItems: flattenMenuItems(newMenuItems),
    }),
  fetchMenuDef: async function ({ t, REQUEST_HEADERS, logout }) {
    set({ fetchingMenu: true });
    const url = SERVER_HOST() + API_MENU_MAKER + GET_MENU_METHOD;
    const response = await fetch(url, {
      method: METHOD_GET,
      headers: REQUEST_HEADERS,
    })
      .then((res) => res.json())
      .then((jsonResult) => {
        return jsonResult;
      })
      .catch((err) => {
        return err;
      });

    if (response?.status === HTTP_STATUS_UNAUTHORIZED && !_.isNil(logout)) {
      logout(true);
    } else {
      if (!_.isNil(response) && !_.isNil(response.dataResponse)) {
        const { ok, errorMsg, dataResponse } = response;
        if (ok) {
          const menuItems = dataResponse?.menu;
          const flattenedMenuItems = flattenMenuItems(menuItems);
          set({
            menuItems: menuItems,
            flattenedMenuItems: flattenedMenuItems,
          });
        } else {
          toast.error(`${t("ERROR_FETCHING_MENU")} ${errorMsg}`, {
            containerId: TOAST_CONTAINER_LAYOUT,
            autoClose: 5000,
            closeOnClick: false,
          });
        }
      } else {
        toast.error(t("ERROR_RESOURCE_NOT_FOUND_TEXT"), {
          containerId: TOAST_CONTAINER_LAYOUT,
          toastId: TOAST_ID_SERVER_OFF,
          autoClose: 5000,
          closeOnClick: false,
        });
      }
    }

    set({ fetchingMenu: false });
  },
  fetchingHomeItems: false,
  setFetchingHomeItems: (newState) => set({ fetchingHomeItems: newState }),
  dashboardSections: [],
  setDashboardSections: (newDashboardSections) =>
    set({ dashboardSections: newDashboardSections }),
  backgroundImg: null,
  setBackgroundImg: (newBackgroundImg) =>
    set({ backgroundImg: newBackgroundImg }),
  callServerToGetDashboard: async function ({ t, REQUEST_HEADERS, logout }) {
    set((state) => ({ fetchingHomeItems: !state.fetchingHomeItems }));
    const response = await dashboardAPI.getDashboard({ REQUEST_HEADERS });
    let toastContent = null;

    if (response) {
      const { status } = response;

      if (status === HTTP_STATUS_UNAUTHORIZED && !_.isNil(logout)) {
        logout(true);
      } else {
        if (status === HTTP_STATUS_NOT_FOUND) {
          toastContent = (
            <CustomBodyToast
              title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
              msg={null}
            />
          );
        } else {
          const { ok, errorMsg: msg, dataResponse } = response;
          if (!ok) {
            toastContent = (
              <CustomBodyToast title={t("FORM_ERROR_ACTION")} msg={msg} />
            );
          } else {
            const {
              backgroundURL,
              dashboard,
              isCustomHome,
              selectedHomeSolution,
            } = dataResponse;
            set({
              dashboardSections: dashboard,
              backgroundImg: backgroundURL,
              isCustomHome: isCustomHome,
              selectedHomeSolution: selectedHomeSolution || "",
            });
          }
        }
      }
    }

    if (toastContent !== null) {
      toast.error(toastContent, {
        containerId: TOAST_CONTAINER_LAYOUT,
        toastId: TOAST_ID_SERVER_OFF,
        autoClose: 5000,
        closeOnClick: false,
      });
    }

    set({ fetchingHomeItems: false });
  },
  selectedHomeSolution: "",
  changeSelectedHomeSolution: async ({
    t,
    REQUEST_HEADERS,
    logout,
    homeName,
    newHomeSolution,
  }) => {
    set({
      selectedHomeSolution: !_.isNil(newHomeSolution) ? newHomeSolution : "",
    });
    const saveActualDashboardFn = get().saveActualDashboard;
    if (!isNil(saveActualDashboardFn) && isFunction(saveActualDashboardFn)) {
      await saveActualDashboardFn({
        t,
        REQUEST_HEADERS,
        logout,
        homeName,
        selectedHomeSolution: newHomeSolution,
      });
    }
  },
  isEditing: false,
  dashboardSectionsBak: [],
  isCustomHome: false,
  allHidden: false,
  backgroundImgBak: null,
  toggleEditing: function () {
    let actualEditing = get().isEditing;
    let actualDashboardSections = _.cloneDeep(get().dashboardSections);
    let actualBackgroundImg = get().backgroundImg;
    if (!actualEditing) {
      set(() => ({
        dashboardSectionsBak: actualDashboardSections,
        backgroundImgBak: actualBackgroundImg,
      }));
    }
    set((state) => ({ isEditing: !state.isEditing }));
  },
  cancelEditing: function () {
    set((state) => ({
      isEditing: false,
      dashboardSections: state.dashboardSectionsBak,
      dashboardSectionsBak: [],
      allHidden: false,
      backgroundImg: state.backgroundImgBak,
      backgroundImgBak: null,
    }));
  },
  orderDS: function ({ ds, instruction }) {
    const actualDashboardSections = get().dashboardSections;
    const indexOf = actualDashboardSections.indexOf(ds);
    if (indexOf > -1) {
      const toMove = getNewPositionBasedOnInstruction(
        actualDashboardSections,
        indexOf,
        instruction
      );
      set({
        dashboardSections: moveItem(actualDashboardSections, indexOf, toMove),
      });
    }
  },
  orderItem: function ({ sectionIndex, cardIndex, instruction }) {
    if (sectionIndex > -1 && cardIndex > -1) {
      let actualDashboardSections = _.cloneDeep(get().dashboardSections);
      const actualItems = actualDashboardSections[sectionIndex]?.items;

      if (isNil(actualItems) && isEmpty(actualItems)) {
        return;
      }

      const toMove = getNewPositionBasedOnInstruction(
        actualItems,
        cardIndex,
        instruction
      );

      const arrayOfItemsToSet = moveItem(actualItems, cardIndex, toMove);
      actualDashboardSections[sectionIndex].items = arrayOfItemsToSet;

      set({
        dashboardSections: actualDashboardSections,
      });
    }
  },
  changeSizeOfCard: function ({ sectionIndex, cardIndex, newSize }) {
    const actualDashboardSections = get().dashboardSections;
    if (
      !isNil(actualDashboardSections) &&
      !isEmpty(actualDashboardSections) &&
      !isNil(actualDashboardSections[sectionIndex]) &&
      !isNil(actualDashboardSections[sectionIndex].items) &&
      !isNil(actualDashboardSections[sectionIndex].items[cardIndex])
    ) {
      const itemToChange =
        actualDashboardSections[sectionIndex].items[cardIndex];
      actualDashboardSections[sectionIndex].items[cardIndex] = {
        ...itemToChange,
        itemSize: newSize,
      };
      set({
        dashboardSections: actualDashboardSections,
      });
    }
  },
  changeColorOfCard: function ({ sectionIndex, cardIndex, newColor }) {
    const actualDashboardSections = get().dashboardSections;
    if (
      !isNil(actualDashboardSections) &&
      !isEmpty(actualDashboardSections) &&
      !isNil(actualDashboardSections[sectionIndex]) &&
      !isNil(actualDashboardSections[sectionIndex].items) &&
      !isNil(actualDashboardSections[sectionIndex].items[cardIndex])
    ) {
      const itemToChange =
        actualDashboardSections[sectionIndex].items[cardIndex];
      actualDashboardSections[sectionIndex].items[cardIndex] = {
        ...itemToChange,
        color: newColor,
      };
      set({
        dashboardSections: actualDashboardSections,
      });
    }
  },
  changeHiddenOfCard: function ({ sectionIndex, cardIndex, newHidden }) {
    const actualDashboardSections = get().dashboardSections;
    if (
      !isNil(actualDashboardSections) &&
      !isEmpty(actualDashboardSections) &&
      !isNil(actualDashboardSections[sectionIndex]) &&
      !isNil(actualDashboardSections[sectionIndex].items) &&
      !isNil(actualDashboardSections[sectionIndex].items[cardIndex])
    ) {
      const itemToChange =
        actualDashboardSections[sectionIndex].items[cardIndex];
      actualDashboardSections[sectionIndex].items[cardIndex] = {
        ...itemToChange,
        hidden: newHidden,
      };
      set({
        dashboardSections: actualDashboardSections,
      });
    }
  },
  changeHiddenOfSection: function ({ sectionIndex, newHidden }) {
    const actualDashboardSections = get().dashboardSections;
    if (
      !isNil(actualDashboardSections) &&
      !isEmpty(actualDashboardSections) &&
      !isNil(actualDashboardSections[sectionIndex])
    ) {
      const itemToChange = actualDashboardSections[sectionIndex];
      actualDashboardSections[sectionIndex] = {
        ...itemToChange,
        hidden: newHidden,
      };
      set({
        dashboardSections: actualDashboardSections,
      });
    }
  },
  addNewBand: function ({ newBandName, position, items, i18n, newTitleIcon }) {
    const actualDashboardSections = get().dashboardSections;

    const itemsToSet =
      isNil(items) && isEmpty(items)
        ? null
        : items
            .filter((x) => x?.hidden === false && x?.inProduction === true)
            .map((i) => {
              return {
                formName: i?.formName,
                formPath: i?.formPath,
                route: i?.route,
                icon: i?.name,
                propText: getLocaleText(i?.propText, i18n),
                isKpi: i?.isKpi,
                itemSize: i?.isKpi
                  ? DASHBOARD_CARD_EXTRA
                  : DASHBOARD_CARD_MEDIUM,
                isExecution: i?.isExecution,
                defaultPinned: i?.defaultPinned,
              };
            });

    const newBand = {
      cardSize: DASHBOARD_CARD_MEDIUM,
      group: null,
      headerImg: null,
      titleIcon: newTitleIcon,
      titleText: newBandName,
      items: itemsToSet,
    };

    if (position === 1) {
      set({
        dashboardSections: [newBand, ...actualDashboardSections],
      });
    } else {
      set({
        dashboardSections: [...actualDashboardSections, newBand],
      });
    }
  },
  addItemToBand: function ({ sectionIndex, newItems, position, i18n }) {
    let actualDashboardSections = get().dashboardSections;
    let actualBand = actualDashboardSections[sectionIndex] || null;
    if (actualBand) {
      let actualItems = actualBand?.items;
      if (actualItems) {
        const itemsToSet =
          isNil(newItems) && isEmpty(newItems)
            ? []
            : newItems
                .filter((x) => x?.hidden === false && x?.inProduction === true)
                .map((i) => {
                  return {
                    formName: i?.formName,
                    formPath: i?.formPath,
                    route: i?.route,
                    icon: i?.name,
                    propText: getLocaleText(i?.propText, i18n),
                    isKpi: i?.isKpi,
                    itemSize: i?.isKpi
                      ? DASHBOARD_CARD_EXTRA
                      : DASHBOARD_CARD_MEDIUM,
                    isExecution: i?.isExecution,
                    defaultPinned: i?.defaultPinned,
                  };
                });
        if (position === 1) {
          actualItems = [...itemsToSet, ...actualItems];
        } else {
          actualItems = [...actualItems, ...itemsToSet];
        }
        actualBand.items = actualItems;
        actualDashboardSections.splice(sectionIndex, 1, actualBand);
        set({
          dashboardSections: actualDashboardSections,
        });
      }
    }
  },
  deleteItemOfBand: function ({ sectionIndex, cardIndex }) {
    const actualDashboardSections = _.cloneDeep(get().dashboardSections);
    if (
      !isNil(actualDashboardSections) &&
      !isEmpty(actualDashboardSections) &&
      !isNil(actualDashboardSections[sectionIndex]) &&
      !isNil(actualDashboardSections[sectionIndex].items) &&
      !isNil(actualDashboardSections[sectionIndex].items[cardIndex])
    ) {
      actualDashboardSections[sectionIndex].items.splice(cardIndex, 1);
      set({
        dashboardSections: actualDashboardSections,
      });
    }
  },
  deleteBand: function ({ sectionIndex }) {
    let actualDashboardSections = _.cloneDeep(get().dashboardSections);
    if (
      !isNil(actualDashboardSections) &&
      !isEmpty(actualDashboardSections) &&
      !isNil(actualDashboardSections[sectionIndex])
    ) {
      actualDashboardSections.splice(sectionIndex, 1);
      set({
        dashboardSections: actualDashboardSections,
      });
    }
  },
  saveActualDashboard: async function ({
    t,
    REQUEST_HEADERS,
    logout,
    homeName,
    selectedHomeSolution,
  }) {
    const applyLoader = isNil(selectedHomeSolution);

    if (applyLoader) {
      set((state) => ({ fetchingHomeItems: !state.fetchingHomeItems }));
    }

    const selecteHomeSolutionToUse = !isNil(selectedHomeSolution)
      ? selectedHomeSolution
      : get().selectedHomeSolution;

    const body = {
      backgroundURL: get().backgroundImg,
      dashboard: get().dashboardSections,
      selectedHomeSolution: selecteHomeSolutionToUse,
    };

    const response = await dashboardAPI.saveDashboard({
      REQUEST_HEADERS,
      homeName,
      body,
    });

    let toastContent = null;

    if (response) {
      const { status } = response;

      if (status === HTTP_STATUS_UNAUTHORIZED && !_.isNil(logout)) {
        logout(true);
      } else {
        if (status === HTTP_STATUS_NOT_FOUND) {
          toastContent = (
            <CustomBodyToast
              title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
              msg={null}
            />
          );
        } else {
          const { ok, errorMsg: msg } = response;
          if (!ok) {
            toastContent = (
              <CustomBodyToast title={t("FORM_ERROR_ACTION")} msg={msg} />
            );
          } else {
            set(() => ({ isCustomHome: true }));
          }
        }
      }
    }

    if (toastContent !== null) {
      toast.error(toastContent, {
        containerId: TOAST_CONTAINER_LAYOUT,
        toastId: "saveActualDashboard",
      });
    }

    if (applyLoader) {
      set({ fetchingHomeItems: false, isEditing: false });
    }
  },
  returnToDefaultDashboard: async function ({
    t,
    REQUEST_HEADERS,
    logout,
    homeName,
  }) {
    set((state) => ({ fetchingHomeItems: !state.fetchingHomeItems }));

    const response = await dashboardAPI.returnToDefault({
      REQUEST_HEADERS,
      homeName,
    });

    let toastContent = null;

    if (response) {
      const { status } = response;

      if (status === HTTP_STATUS_UNAUTHORIZED && !_.isNil(logout)) {
        logout(true);
      } else {
        if (status === HTTP_STATUS_NOT_FOUND) {
          toastContent = (
            <CustomBodyToast
              title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
              msg={null}
            />
          );
        } else {
          const { ok, errorMsg: msg, dataResponse } = response;
          if (!ok) {
            toastContent = (
              <CustomBodyToast title={t("FORM_ERROR_ACTION")} msg={msg} />
            );
          } else {
            const { backgroundURL, dashboard, isCustomHome } = dataResponse;
            set({
              dashboardSections: dashboard,
              backgroundImg: backgroundURL,
              isCustomHome: isCustomHome,
            });
          }
        }
      }
    }

    if (toastContent !== null) {
      toast.error(toastContent, {
        containerId: TOAST_CONTAINER_LAYOUT,
        toastId: "returnToDefaultDashboard",
      });
    }

    set({ fetchingHomeItems: false, isEditing: false });
  },
  toggleAllBands: function () {
    const actualDashboardSections = get().dashboardSections;
    if (!isNil(actualDashboardSections) && !isEmpty(actualDashboardSections)) {
      const atLeastOneShow = some(actualDashboardSections, function (s) {
        return (
          !isNil(s) &&
          (s?.hidden === false || isNil(s?.hidden) || !has(s, "hidden"))
        );
      });
      if (atLeastOneShow) {
        // hide all
        set({
          dashboardSections: actualDashboardSections.map((x) => {
            return {
              ...x,
              hidden: true,
            };
          }),
          allHidden: true,
        });
      } else {
        // show all
        set({
          dashboardSections: actualDashboardSections.map((x) => {
            return {
              ...x,
              hidden: false,
            };
          }),
          allHidden: false,
        });
      }
    }
  },
  processesList: [],
  callServerToGetProcessesList: async function ({ REQUEST_HEADERS, logout }) {
    const response = await dashboardAPI.getSolutionsTasks({ REQUEST_HEADERS });

    if (response) {
      const { status } = response;
      if (status === HTTP_STATUS_UNAUTHORIZED && !_.isNil(logout)) {
        logout(true);
      } else {
        if (status !== HTTP_STATUS_NOT_FOUND) {
          const { ok, dataResponse } = response;
          if (ok) {
            set({ processesList: dataResponse });
          }
        }
      }
    }
  },
  favItems: [],
  isUpdatingFavItems: true,
  callServerToGetFavItems: async function ({ REQUEST_HEADERS, logout }) {
    set({ isUpdatingFavItems: true });
    const response = await dashboardAPI.getUserConfigFavs({ REQUEST_HEADERS });

    if (response) {
      const { status } = response;
      if (status === HTTP_STATUS_UNAUTHORIZED && !_.isNil(logout)) {
        logout(true);
      } else {
        if (status !== HTTP_STATUS_NOT_FOUND) {
          const { ok, dataResponse } = response;
          if (ok) {
            set({ favItems: dataResponse });
          }
        }
      }
    }
    set({ isUpdatingFavItems: false });
  },
  callServerToAddFavItem: async function ({ REQUEST_HEADERS, logout, id }) {
    set({ isUpdatingFavItems: true });
    const response = await dashboardAPI.saveUserConfigFav({
      REQUEST_HEADERS,
      id,
    });

    if (response) {
      const { status } = response;
      if (
        status === HTTP_STATUS_UNAUTHORIZED &&
        !_.isNil(logout) &&
        _.isFunction(logout)
      ) {
        logout(true);
      } else {
        if (status !== HTTP_STATUS_NOT_FOUND) {
          const { ok } = response;
          if (ok) {
            const functionToGetAgain = get().callServerToGetFavItems;
            if (
              !isNil(functionToGetAgain) &&
              _.isFunction(functionToGetAgain)
            ) {
              await functionToGetAgain({ REQUEST_HEADERS, logout });
            }
          }
        }
      }
    }
    set({ isUpdatingFavItems: false });
  },
  callServerToDeleteFavItem: async function ({
    t,
    REQUEST_HEADERS,
    logout,
    id,
  }) {
    set({ isUpdatingFavItems: true });
    const response = await dashboardAPI.deleteUserConfigFav({
      REQUEST_HEADERS,
      id,
    });

    if (response) {
      const { status, error } = response;
      if (
        status === HTTP_STATUS_UNAUTHORIZED &&
        !_.isNil(logout) &&
        _.isFunction(logout)
      ) {
        logout(true);
      } else if (status === HTTP_STATUS_INTERNAL_SERVER_ERROR) {
        toast.error(t("ERROR_RESOURCE_NOT_FOUND_TEXT"), {
          containerId: TOAST_CONTAINER_LAYOUT,
        });
      } else if (
        status === HTTP_STATUS_TIMEOUT ||
        status === HTTP_STATUS_UNAVAILABLE_SERVICE
      ) {
        toast.error(error, {
          containerId: TOAST_CONTAINER_LAYOUT,
        });
      } else {
        if (status !== HTTP_STATUS_NOT_FOUND) {
          const { ok } = response;
          if (ok) {
            const functionToGetAgain = get().callServerToGetFavItems;
            if (
              !isNil(functionToGetAgain) &&
              _.isFunction(functionToGetAgain)
            ) {
              await functionToGetAgain({ REQUEST_HEADERS, logout });
            }
          }
        }
      }
    }
    set({ isUpdatingFavItems: false });
  },
  customHeaderLogo: null,
  callServerToGetCustomHeaderLogo: async function ({
    REQUEST_HEADERS,
    logout,
    company,
  }) {
    let customLogoResponse = null;
    const response = await dashboardAPI.getCustomHeaderLogoByCompany({
      REQUEST_HEADERS,
      company,
    });

    if (response) {
      const { status } = response;
      if (status === HTTP_STATUS_UNAUTHORIZED && !_.isNil(logout)) {
        logout(true);
      } else {
        if (status !== HTTP_STATUS_NOT_FOUND) {
          const { ok, dataResponse } = response;
          if (ok) {
            customLogoResponse = dataResponse;
            set({ customHeaderLogo: dataResponse });
          }
        }
      }
    }
    return customLogoResponse;
  },
}));

export { useAccessesStore };
