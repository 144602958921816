import { isNil } from "lodash";
import { KEY_TAB } from "./UtilKeys";
import { EMPLOYEE_STORE_HOME_ROUTE } from "../solutions/employee-store/routes/EmployeeStoreRouter";

export const ELEMENT_TYPE_BTN = "button";
export const ELEMENT_TYPE_TEXT = "text";
export const ELEMENT_TYPE_NUMBER = "number";

export function checkTypeOfElementById(id, type) {
  if (
    id &&
    id !== "" &&
    document &&
    document.getElementById(id) !== null &&
    document.getElementById(id).getAttribute("type") === type
  ) {
    return true;
  } else {
    return false;
  }
}

export function wasTab(e) {
  let result = false;
  const code = e.keyCode ? e.keyCode : e.which;
  if (code === KEY_TAB) {
    result = true;
  }
  return result;
}

export function refreshPage({ isEms }) {
  if (!isNil(isEms) && isEms === true) {
    window.location.replace(EMPLOYEE_STORE_HOME_ROUTE);
  } else {
    window.location.replace("/");
  }
}

export function focusElementByIdInEvent(e) {
  let id = null;
  if (e && !isNil(e.relatedTarget)) {
    id = e.relatedTarget.getAttribute("id");
    if (id && id !== "") {
      if (document && document.getElementById(id) !== null) {
        document.getElementById(id).focus();
      }
    }
  }
}

export async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}

export async function copyTextToClipboardFromDialog(text) {
  try {
    // Asegurar que el documento tiene el foco
    if (!document.hasFocus()) {
      window.focus();
    }

    // Intentar copiar usando la API moderna del portapapeles
    if (navigator.clipboard && typeof navigator.clipboard.writeText === "function") {
      await navigator.clipboard.writeText(text);
      return true; // Éxito
    }

    // Si la API moderna falla o no está disponible, usar el método antiguo
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Estilos para hacer que el textarea no se vea
    textArea.style.position = "fixed";
    textArea.style.top = "-9999px";
    textArea.style.left = "-9999px";

    document.body.appendChild(textArea);
    textArea.select();
    const successful = document.execCommand("copy");
    document.body.removeChild(textArea);

    if (!successful) {
      throw new Error("execCommand failed");
    }

    return true; // Éxito
  } catch (error) {
    console.error("Error copying text to clipboard:", error);
    return false; // Fallo
  }
}