import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import {
  Icon,
  IconButton,
  Tooltip,
  useTheme,
  CircularProgress,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import * as _ from "lodash";
import GetAppIcon from "@material-ui/icons/GetApp";
import * as XLSX from "xlsx-js-style";

import {
  FORM_OPERATION_EXECUTION,
  FORM_OPERATION_NEW,
  FORM_OPERATON_EDIT,
  MAX_SIZE_IN_MB_FILES,
  TOAST_CONTAINER_FORM_CONTAINER,
} from "../../../../../util/Constants";
import ConfirmationDeleteDialog from "../ConfirmationDeleteDialog";
import GlobalSearchFilterInput from "./GlobalSearchFilterInput";
import * as UtilExcel from "../../../../../util/UtilExcel";
import {
  callServerToSetMultipleValues,
  getSelectionServices,
  resolveFieldAgainstShadowStatus,
} from "../../../../../util/UtilForm";
import {
  SEL_CLI_PASTE_AREA,
  SEL_GENERICA,
} from "../../../clientServices/services";
import ContextMenu from "../../../handlers/HandlerContextMenu";
import { useRequestHeaders } from "../../../../../core/hooks/useRequestHeaders";
import { toast } from "react-toastify";
import { AVAILABLE_FORMATS } from "../../../../dialogs/fileChooserDialog/formats";
import QueryActionLoaderModal from "../../../../reportsContainer/components/QueryActionLoaderModal";

const VALID_ADD_ROWS_OPERATIONS = [
  FORM_OPERATION_NEW,
  FORM_OPERATON_EDIT,
  FORM_OPERATION_EXECUTION,
];

const OPEN_MENU_MODE_PASTE_AREA = "OPEN_MENU_MODE_PASTE_AREA";
const OPEN_MENU_MODE_GLOBAL_SEL_GEN = "OPEN_MENU_MODE_GLOBAL_SEL_GEN";

const INITIAL_MENU_POSITION = {
  x: null,
  y: null,
  mode: OPEN_MENU_MODE_GLOBAL_SEL_GEN,
};

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginLeft: 7,
  },
  iconBtnStyles: {
    marginRight: 3,
    padding: 10,
  },

  typoNote: {
    fontStyle: "italic",
  },
  uploadExcelBtn: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "25px",
    border: `1px solid`,
    backgroundColor: `${theme.palette.content.mainColor}`,
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: `${theme.palette.content.mainColor}CC`,
      color: "#FFFFFF",
      transform: "scale(1.015)",
    },
  },
}));

function createObjectFromArrayIndexes(arr) {
  var resultado = {};
  for (var i = 0; i < arr.length; i++) {
    resultado[i] = true;
  }
  return resultado;
}

function updateLineNumber(array) {
  let lineCountMap = {};

  for (let i = 0; i < array.length; i++) {
    const currentId = array[i].idControl;

    if (array[i].line !== 0 && !(currentId in lineCountMap)) {
      lineCountMap[currentId] = 1;
    }

    if (array[i].line !== 0) {
      array[i].line = lineCountMap[currentId]++;
    }
  }

  return array;
}

function transformRowsToExcelDownload(rows) {
  if (!_.isNil(rows) && !_.isEmpty(rows) && _.isArray(rows)) {
    const transformedArray = rows.map((obj) => {
      const transformedObj = {};
      for (const key in obj) {
        if (
          typeof obj[key] === "object" &&
          obj[key] !== null &&
          "value" in obj[key]
        ) {
          const valToSet = obj[key].value;
          transformedObj[key] = !_.isNil(valToSet) ? valToSet : "";
        } else {
          const valToSet = obj[key];
          transformedObj[key] = !_.isNil(valToSet) ? valToSet : "";
        }
      }
      return transformedObj;
    });
    return transformedArray || [];
  } else {
    return [];
  }
}

export default function GlobalFilter(props) {
  const {
    preGlobalFilteredRows,
    rows,
    handleAddNewRow,
    enableAddRow,
    selectedRowIds,
    handleDeleteSelectedRows,
    operation,
    loading,
    globalFilter,
    setGlobalFilter,
    idTable,
    selectedFlatRows,
    columns,
    titleBlock,
    enableDeleteRow,
    maxRows,
    shadowStatus,
    denyAddRow,
    formContext,
    formInstanceId,
    processServerResponse,
    disableMassiveGridLoad,
    formSchema,
    formData,
    isBlockDisabledByWizard,
  } = props;

  const theme = useTheme();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const buttonAddRef = useRef(null);
  const classes = useStyles();
  const [exporting, setExporting] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState(INITIAL_MENU_POSITION);
  const [openPreLoadExcelDialog, setOpenPreLoadExcelDialog] = useState(false);
  const [uploadedExcelFile, setUploadedExcelFile] = useState(null);
  const [preLoadedPasteInfo, setPreLoadedPasteInfo] = useState("");
  const inputUploadExcelRef = useRef(null);

  const REQUEST_HEADERS = useRequestHeaders();

  const [openQueryActionLoaderModal, setOpenQueryActionLoaderModal] =
    useState(false);

  const sizeRawRowsWithoutFiltering = _.size(preGlobalFilteredRows);
  const lineToUseInSpecialSets =
    sizeRawRowsWithoutFiltering > 0 ? sizeRawRowsWithoutFiltering + 1 : 1;

  const handleConfirm = (confirmed) => {
    if (confirmed) handleDeleteSelectedRows(selectedRowIds, selectedFlatRows);
    setOpen(false);
  };

  function onClickButtonAdd() {
    handleAddNewRow();
  }

  function isButtonAddDisabled() {
    if (loading) {
      return true;
    } else {
      return false;
    }
  }

  const handleDownloadExcel = async () => {
    setExporting(true);

    const isSelectedRows =
      !_.isNil(selectedFlatRows) && !_.isEmpty(selectedFlatRows);

    const columnsToUse = columns
      .filter(
        (c) =>
          !c?.hidden &&
          !_.isEqual("line", c?.accessor) &&
          !_.isEqual("File", c?.inputType)
      )
      .map((c) => {
        return {
          name: c?.Header,
          value: c?.accessor,
          type: c?.type,
          mask: c?.mask || "",
        };
      });

    const data = isSelectedRows
      ? selectedFlatRows.map((row) => row.values || row.original)
      : rows.map((row) => row.values || row.original);

    const dataTouUse = transformRowsToExcelDownload(data);

    UtilExcel.downloadExcelUniqueSheet({
      data: dataTouUse,
      columns: columnsToUse,
      fileName:
        !_.isNil(titleBlock) && !_.isEmpty(titleBlock)
          ? titleBlock
          : t("DEFAULT_EXCEL_SHEET_FILE"),
      sheetName: "1",
    });

    setTimeout(() => {
      setExporting(false);
    }, [100]);
  };

 
  const handleTableCopy = () => {
    const data = rows.map((row) => row.values || row.original);
    const dataToUse = transformRowsToExcelDownload(data);
 
    const columnsToUse = columns
    .filter(
      (c) =>
        !c?.hidden &&
        !_.isEqual("line", c?.accessor) &&
        !_.isEqual("File", c?.inputType)
    )
    .map((c) => {
      return {
        name: c?.Header,
        value: c?.accessor,
        type: c?.type,
        mask: c?.mask || "",
      };
    });
    const cabecera = columnsToUse.map((cabecera) => cabecera.name).join("\t");
    const filas = dataToUse.map((fila) => columnsToUse.map((col) => fila[col.value] || "").join("\t")).join("\n");
    const dataTable = `${cabecera}\n${filas}`;
    navigator.clipboard.writeText(dataTable);
  }


  const blockAddRowsByMax = useMemo(() => {
    if (_.isNil(maxRows)) {
      return false;
    }

    if (_.isNil(rows) || _.isEmpty(rows) || !_.isArray(rows)) {
      return false;
    }

    if (_.isString(maxRows)) {
      const valueInShadowStatus = resolveFieldAgainstShadowStatus({
        field: maxRows,
        shadowStatus,
        completeIdControl: maxRows,
      });
      if (
        !_.isNil(valueInShadowStatus) &&
        !_.isNil(_.toNumber(valueInShadowStatus)) &&
        !_.isNaN(_.toNumber(valueInShadowStatus))
      ) {
        return rows.length >= _.toNumber(valueInShadowStatus);
      } else {
        return false;
      }
    } else {
      return rows.length >= maxRows;
    }
  }, [maxRows, rows, shadowStatus]);

  const blockAddRowsByDenial = useMemo(() => {
    if (_.isNil(denyAddRow)) {
      return false;
    }

    if (_.isString(denyAddRow)) {
      const valueInShadowStatus = resolveFieldAgainstShadowStatus({
        field: denyAddRow,
        shadowStatus,
        completeIdControl: denyAddRow,
      });
      if (!_.isNil(valueInShadowStatus)) {
        return (
          _.isEqual("S", _.toUpper(_.toString(valueInShadowStatus))) ||
          _.isEqual("TRUE", _.toUpper(_.toString(valueInShadowStatus))) ||
          _.isEqual("Y", _.toUpper(_.toString(valueInShadowStatus)))
        );
      } else {
        return false;
      }
    } else {
      return denyAddRow;
    }
  }, [denyAddRow, shadowStatus]);

  const blockDisableMassiveGridLoad = useMemo(() => {
    if (_.isNil(disableMassiveGridLoad)) {
      return false;
    }

    if (_.isString(disableMassiveGridLoad)) {
      const valueInShadowStatus = resolveFieldAgainstShadowStatus({
        field: disableMassiveGridLoad,
        shadowStatus,
        completeIdControl: disableMassiveGridLoad,
      });
      if (!_.isNil(valueInShadowStatus)) {
        return (
          _.isEqual("S", _.toUpper(_.toString(valueInShadowStatus))) ||
          _.isEqual("TRUE", _.toUpper(_.toString(valueInShadowStatus))) ||
          _.isEqual("Y", _.toUpper(_.toString(valueInShadowStatus)))
        );
      } else {
        return false;
      }
    } else {
      return disableMassiveGridLoad;
    }
  }, [disableMassiveGridLoad, shadowStatus]);

  const commonThemedIconStyles = useMemo(() => {
    return {
      color: theme.palette.content.mainColor,
      fontSize: 16,
      width: "auto",
      height: "auto",
      padding: 1,
    };
  }, [theme]);

  const selectionServicesInColumns = useMemo(() => {
    if (!_.isNil(columns) && !_.isEmpty(columns)) {
      const selectionArrays = columns.map((column) =>
        getSelectionServices(column)
      );
      const filteredArrays = _.filter(selectionArrays, (o) => !_.isNil(o));
      return _.flatMap(filteredArrays);
    }
  }, [columns]);

  const isThereSomeCliAreaGenerica = useMemo(() => {
    if (!_.includes(VALID_ADD_ROWS_OPERATIONS, operation)) {
      return false;
    }

    return (
      !_.isNil(selectionServicesInColumns) &&
      !_.isEmpty(selectionServicesInColumns) &&
      _.some(
        _.filter(selectionServicesInColumns, function (s) {
          return (
            _.isNil(s?.hideGlobalButtonInGrid) ||
            s?.hideGlobalButtonInGrid === false
          );
        }),
        {
          enabled: true,
          serviceName: SEL_CLI_PASTE_AREA,
        }
      )
    );
  }, [selectionServicesInColumns, operation]);

  const isThereSomeGlobalSelGenerica = useMemo(() => {
    if (!_.includes(VALID_ADD_ROWS_OPERATIONS, operation)) {
      return false;
    }

    return (
      !_.isNil(selectionServicesInColumns) &&
      !_.isEmpty(selectionServicesInColumns) &&
      _.some(
        _.filter(selectionServicesInColumns, function (s) {
          return (
            _.isNil(s?.hideGlobalButtonInGrid) ||
            s?.hideGlobalButtonInGrid === false
          );
        }),
        {
          enabled: true,
          serviceName: SEL_GENERICA,
          exposeGlobal: true,
        }
      )
    );
  }, [selectionServicesInColumns, operation]);

  const selectionServicesToUseByMode = useMemo(() => {
    const baseServices =
      !_.isNil(selectionServicesInColumns) &&
      !_.isEmpty(selectionServicesInColumns)
        ? selectionServicesInColumns
        : [];
    if (menuPosition?.mode === OPEN_MENU_MODE_GLOBAL_SEL_GEN) {
      return _.filter(baseServices, {
        enabled: true,
        serviceName: SEL_GENERICA,
        exposeGlobal: true,
      });
    } else {
      return _.filter(baseServices, {
        enabled: true,
        serviceName: SEL_CLI_PASTE_AREA,
      });
    }
  }, [selectionServicesInColumns, menuPosition]);

  const handleOpenMenu = useCallback(
    (event, mode) => {
      if (
        !_.isNil(selectionServicesInColumns) &&
        !_.isEmpty(selectionServicesInColumns)
      ) {
        event.preventDefault();
        setMenuPosition({
          x: event.clientX,
          y: event.clientY,
          mode,
        });
        setOpenMenu(true);
      }
    },
    [selectionServicesInColumns]
  );

  const handleCloseMenu = () => {
    setOpenMenu(false);
    setMenuPosition(INITIAL_MENU_POSITION);
    setOpenPreLoadExcelDialog(false);
    setPreLoadedPasteInfo("");
  };

  //Changes the value written into the state and the form data booooom
  const changeValue = async (
    values,
    overrideRows,
    ignoreSetGridData,
    sendCompletedEndpoint
  ) => {
    setOpenQueryActionLoaderModal(true);
    const preSelectedRowsId = createObjectFromArrayIndexes(
      preGlobalFilteredRows
    );
    const preSelectedRows = _.clone(preGlobalFilteredRows);

    if (
      overrideRows &&
      (_.isNil(ignoreSetGridData) || ignoreSetGridData === false)
    ) {
      await handleDeleteSelectedRows(preSelectedRowsId, preSelectedRows);
    }

    const valuesToSet = overrideRows ? updateLineNumber(values) : values;
    const response = await callServerToSetMultipleValues(
      {
        formInstanceId,
        values: valuesToSet,
      },
      REQUEST_HEADERS,
      sendCompletedEndpoint
    );

    const linesToApply = overrideRows ? 1 : lineToUseInSpecialSets;

    setTimeout(() => {
      setOpenQueryActionLoaderModal(false);
    }, 300);

    processServerResponse(response, null, true, null, linesToApply, null);
  };

  const preExcelHandling = async (e) => {
    if (e) {
      e.preventDefault();
    }

    setOpenPreLoadExcelDialog(true);
  };

  const handleFileExcelChange = (e) => {
    const toastConfig = {
      toastId: "handleFileExcelChange",
      containerId: TOAST_CONTAINER_FORM_CONTAINER,
    };
    const file = e.target.files[0];
    if (file) {
      if (
        file.type === AVAILABLE_FORMATS.xls.mimeType ||
        file.type === AVAILABLE_FORMATS.xlsx.mimeType
      ) {
        // check if it is less 50MB
        if (file.size <= MAX_SIZE_IN_MB_FILES) {
          setUploadedExcelFile(file);
        } else {
          toast.error(t("FILE_UPLOAD_GENERIC_ERROR"), toastConfig);
        }
      } else {
        toast.error(t("MASSIVE_REJECT_FILE"), toastConfig);
      }
    }
  };

  const processInformationInExcel = useCallback(
    (baseEvent) => {
      const toastId = "processInformationInExcel";
      const toastConfig = {
        toastId,
        containerId: TOAST_CONTAINER_FORM_CONTAINER,
        autoClose: 5000,
      };

      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const binaryString = event.target.result;
          const workbook = XLSX.read(binaryString, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];

          const excelData = [];
          Object.keys(worksheet).forEach((cell) => {
            if (cell[0] === "!") return;
            const cellRef = XLSX.utils.decode_cell(cell);
            const row = cellRef.r + 1;
            const col = cellRef.c + 1;
            const colId = UtilExcel.getColumnIdentifier(col);
            const value = worksheet[cell].v;
            excelData.push({ row, col, colId, value });
          });

          const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          const resultString = data.map((row) => row.join("\t")).join("\n");

          /*if (!_.isNil(resultString) && !_.isEmpty(resultString)) {
            setPreLoadedPasteInfo({ resultString, excelData });
            handleOpenMenu(baseEvent, OPEN_MENU_MODE_PASTE_AREA);
          } else {
            toast.error(
              t("HANDLE_CONTEXT_MENU_ERROR_PROCESSING_PRE_EXCEL"),
              toastConfig
            );
          }*/

          const { isValid, msg } = UtilExcel.validateExcelForCopyPasteFeature(
            excelData,
            t
          );
          if (isValid === false) {
            toast.error(msg, { ...toastConfig, toastId: undefined });
          } else {
            if (!_.isNil(resultString) && !_.isEmpty(resultString)) {
              setPreLoadedPasteInfo({ resultString, excelData });
              handleOpenMenu(baseEvent, OPEN_MENU_MODE_PASTE_AREA);
            } else {
              toast.error(
                t("HANDLE_CONTEXT_MENU_ERROR_PROCESSING_PRE_EXCEL"),
                toastConfig
              );
            }
          }
        } catch (error) {
          toast.error(
            t("HANDLE_CONTEXT_MENU_ERROR_PROCESSING_PRE_EXCEL"),
            toastConfig
          );
        }
      };
      reader.readAsBinaryString(uploadedExcelFile);
    },
    [handleOpenMenu, t, uploadedExcelFile]
  );

  useEffect(() => {
    const cleanFile = () => {
      if (
        !_.isNil(inputUploadExcelRef) &&
        !_.isNil(inputUploadExcelRef.current)
      ) {
        inputUploadExcelRef.current.value = "";
      }
      setUploadedExcelFile(null);
    };

    cleanFile();
    return cleanFile();
  }, [openPreLoadExcelDialog]);

  return (
    <div
      id={`global_filter_container_${idTable}`}
      className={classes.container}
    >
      <QueryActionLoaderModal
        open={openQueryActionLoaderModal}
        msg={t("LOADER_INFO_FORM")}
      />
      {/* GLOBAL ACTIONS CONTAINER */}
      <div
        id={`actions_container_${idTable}`}
        className={classes.actionsContainer}
      >
        {enableAddRow === true &&
          _.includes(VALID_ADD_ROWS_OPERATIONS, operation) && (
            <Tooltip
              placement="top"
              title={
                blockAddRowsByMax === true
                  ? t("MAX_ROWS_REACHED")
                  : blockAddRowsByDenial === true
                  ? t("ADD_ROWS_DENIED")
                  : ""
              }
            >
              <span>
                <IconButton
                  id={`add_new_row_button_${idTable}`}
                  style={{
                    marginRight: 3,
                    padding: 10,
                  }}
                  type="button"
                  onClick={() => {
                    if (
                      blockAddRowsByMax === true ||
                      blockAddRowsByDenial === true
                    ) {
                      return;
                    }
                    onClickButtonAdd();
                  }}
                  disabled={
                    isButtonAddDisabled() === true ||
                    blockAddRowsByMax === true ||
                    blockAddRowsByDenial === true
                  }
                  ref={buttonAddRef}
                  onClickCapture={() => {}}
                >
                  {loading === true ? (
                    <CircularProgress
                      style={{
                        color: theme.palette.content.mainColor,
                        width: 16,
                        height: 16,
                      }}
                    />
                  ) : (
                    <Icon
                      className="fas fa-plus-circle"
                      style={{
                        color:
                          isButtonAddDisabled() === true
                            ? "lightgrey"
                            : theme.palette.content.mainColor,
                        fontSize: 16,
                        width: "auto",
                        height: "auto",
                        padding: 1,
                      }}
                    />
                  )}
                </IconButton>
              </span>
            </Tooltip>
          )}

        {isThereSomeCliAreaGenerica && (
          <Tooltip
            title={
              blockDisableMassiveGridLoad
                ? t("ADD_ROWS_DENIED")
                : t("HANDLE_CONTEXT_MENU_COPY_BTN_TOOLTIP")
            }
          >
            <span>
              <IconButton
                onClick={(e) => handleOpenMenu(e, OPEN_MENU_MODE_PASTE_AREA)}
                type="button"
                className={classes.iconBtnStyles}
                disabled={blockDisableMassiveGridLoad}
              >
                <Icon className="fas fa-copy" style={commonThemedIconStyles} />
              </IconButton>
            </span>
          </Tooltip>
        )}

        {isThereSomeCliAreaGenerica && (
          <Tooltip
            title={
              blockDisableMassiveGridLoad
                ? t("ADD_ROWS_DENIED")
                : t("HANDLE_CONTEXT_MENU_EXCEL_BTN_TOOLTIP")
            }
          >
            <span>
              <IconButton
                onClick={(e) => preExcelHandling(e)}
                type="button"
                className={classes.iconBtnStyles}
                disabled={blockDisableMassiveGridLoad}
              >
                <Icon
                  className="fas fa-file-excel"
                  style={commonThemedIconStyles}
                />
              </IconButton>
            </span>
          </Tooltip>
        )}

        {isThereSomeGlobalSelGenerica && (
          <Tooltip
            title={
              blockDisableMassiveGridLoad
                ? t("ADD_ROWS_DENIED")
                : t("HANDLE_CONTEXT_MENU_SELECS_BTN_TOOLTIP")
            }
          >
            <span>
              <IconButton
                onClick={(e) =>
                  handleOpenMenu(e, OPEN_MENU_MODE_GLOBAL_SEL_GEN)
                }
                type="button"
                className={classes.iconBtnStyles}
                disabled={blockDisableMassiveGridLoad}
              >
                <Icon
                  className="fas fa-search-plus"
                  style={commonThemedIconStyles}
                />
              </IconButton>
            </span>
          </Tooltip>
        )}

        {enableDeleteRow === true &&
          _.includes(VALID_ADD_ROWS_OPERATIONS, operation) &&
          !_.isEmpty(selectedRowIds) && (
            <Tooltip
              title={`${t("FORM_TABLE_DELETE_PART1")} ${
                Object.keys(selectedRowIds).length
              } ${t("FORM_TABLE_DELETE_PART2")}`}
              placement="right"
            >
              <IconButton
                className={classes.iconBtnStyles}
                type="button"
                onClick={() => setOpen(true)}
              >
                <Icon
                  className="fas fa-trash-alt"
                  style={commonThemedIconStyles}
                />
              </IconButton>
            </Tooltip>
          )}
        <ConfirmationDeleteDialog open={open} handleConfirm={handleConfirm} />
      </div>

      <IconButton
        onClick={() => handleDownloadExcel()}
        className={classes.iconBtnStyles}
        disabled={_.isNil(rows) || _.isEmpty(rows)}
      >
        {exporting ? <CircularProgress size={14} /> : <GetAppIcon />}
      </IconButton>
      <Tooltip title={`${t("LIST_COPY")}`} placement="top">
      <IconButton
        onClick={() => handleTableCopy()}
        className={classes.iconBtnStyles}
        disabled={_.isNil(rows) || _.isEmpty(rows)}
      >   <Icon className="fas fa-file-import" />
      </IconButton>
      </Tooltip>
      <GlobalSearchFilterInput
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        rows={rows}
        count={preGlobalFilteredRows.length}
        isDisabled={isBlockDisabledByWizard === true}
      />

      <Dialog
        open={openPreLoadExcelDialog}
        aria-labelledby="pre-excel-load-dialog-title"
        aria-describedby="pre-excel-load-dialog-description"
      >
        <DialogTitle id="pre-excel-load-dialog-title">
          {t("HANDLE_CONTEXT_MENU_PRELOAD_TITLE")}
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6">
            {t("HANDLE_CONTEXT_MENU_PRELOAD_BODY")}
          </Typography>
          <Typography className={classes.typoNote}>
            {t("HANDLE_CONTEXT_MENU_PRELOAD_PD")}
          </Typography>
          <Button
            className={classes.uploadExcelBtn}
            onClick={(e) => {
              e.preventDefault();
              if (inputUploadExcelRef != null) {
                inputUploadExcelRef.current.click();
              }
            }}
          >
            {!_.isNil(uploadedExcelFile) ? (
              <Typography>{uploadedExcelFile?.name}</Typography>
            ) : (
              <Typography>{t("HANDLE_CONTEXT_MENU_PRELOAD_BTN")}</Typography>
            )}
            <Icon
              className="fas fa-paperclip"
              style={{
                ...commonThemedIconStyles,
                color: "#FFF",
                marginLeft: 10,
              }}
            />
          </Button>
          <input
            id="file_chooser_excel"
            ref={inputUploadExcelRef}
            type="file"
            style={{ display: "none" }}
            multiple={false}
            onChange={handleFileExcelChange}
            value={""}
            accept={`${AVAILABLE_FORMATS.xlsx.mimeType}, ${AVAILABLE_FORMATS.xls.mimeType}`}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={(e) => {
              e.persist();
              processInformationInExcel(e);
            }}
            disabled={_.isNil(uploadedExcelFile)}
          >
            {t("HANDLE_CONTEXT_MENU_PRELOAD_LOAD")}
          </Button>
          <Button onClick={() => setOpenPreLoadExcelDialog(false)}>
            {t("CANCEL")}
          </Button>
        </DialogActions>
      </Dialog>

      {openMenu === true && (
        <ContextMenu
          openMenu={openMenu}
          uniqueID={"global-selection-services-menu"}
          services={selectionServicesToUseByMode}
          menuPosition={menuPosition}
          setValue={changeValue}
          closeMenu={handleCloseMenu}
          formContextData={formContext?.formData}
          line={lineToUseInSpecialSets}
          formInstanceId={formInstanceId}
          auto={selectionServicesToUseByMode?.length === 1}
          isCalledFromGlobalFilterInGrid={true}
          alreadyInfoInGrid={sizeRawRowsWithoutFiltering > 0}
          preLoadedPasteInfo={preLoadedPasteInfo}
          shadowStatus={shadowStatus}
          formSchema={formSchema}
          formData={formData}
        />
      )}
    </div>
  );
}
