import React, { useState, useEffect, useContext, useMemo } from "react";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { isEmpty, isMap, isNil } from "lodash";
import { saveAs } from "file-saver";
import { useRouteMatch } from "react-router-dom";

import {
  callExportJasper,
  callOpenExternalForm,
  notifyServerChangeOperation,
  transformFormStatusFromServer,
} from "../../util/UtilForm";
import {
  DATE_TIME_PATTERN,
  FORM_OPERATION_DELETE,
  FORM_OPERATION_NEW,
  FORM_OPERATION_VIEW,
  FORM_OPERATON_EDIT,
  HTTP_STATUS_NOT_FOUND,
  HTTP_STATUS_UNAUTHORIZED,
  TOAST_CONTAINER_LAYOUT,
} from "../../util/Constants";
import { AuthContext } from "../providers/AuthContext";
import CustomBodyToast from "../../components/toast/CustomBodyToast";
import { useRequestHeaders } from "./useRequestHeaders";
import { useSolutionStore } from "../stores/solutions/";
import { useCallback } from "react";
import {
  APOLLO_FRONT_ONLY_IDENTIFIER,
  AVIS_LOCAL_PROCESS_PARAM,
  AVIS_ZONE_PROCESS_PARAM,
  FENIX_PROCESS_PARAM,
  LABELLING_PROCESS_PARAM,
  ONEPRICE_PROCESS_PARAM,
} from "../../solutions/apollo/apollo-constants";
import { format } from "date-fns";
import { DeviceContext } from "../providers/DeviceContext";

export default function useExternalForm({
  ukey,
  path,
  idForm,
  values,
  operation,
  process,
  openFormLikeRecordsPanel = false,
  solution,
  tableName,
}) {
  const { t } = useTranslation();
  const { auth, logout } = useContext(AuthContext);
  const { deviceInfo } = useContext(DeviceContext);

  const [formData, setFormData] = useState();
  const [initialFormData, setInitialFormData] = useState();
  const [loading, setLoading] = useState(true);
  const [formSchema, setFormSchema] = useState({});
  const [formStatus, setFormStatus] = useState(null);
  const [formInstanceId, setFormInstanceId] = useState(null);
  const [gridLayout, setGridLayout] = useState();
  const [actualOperation, setActualOperation] = useState(operation || null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [executedAction, setExecutedAction] = useState(false);
  const [shadowStatus, setShadowStatus] = useState(null);
  const [anchorElJasperOpt, setAnchorElJasperOpt] = useState(null);
  const [generatingJasper, setGeneratingJasper] = useState(false);
  const [formConfig, setFormConfig] = useState(null);

  const REQUEST_HEADERS = useRequestHeaders();

  const { url } = useRouteMatch();

  const storedBreadcumbRoutes = useSolutionStore(
    (state) => state.storedBreadcumbRoutes
  );

  const apolloStoredBreadcumbRoutes = useSolutionStore(
    (state) => state.apoStoredBreadcumbRoutes
  );

  const labellingStoredBreadcumbRoutes = useSolutionStore(
    (state) => state.labellingStoredBreadcumbRoutes
  );

  const avisStoredBreadcumbRoutes = useSolutionStore(
    (state) => state.avisStoredBreadcumbRoutes
  );

  const avisZoneStoredBreadcumbRoutes = useSolutionStore(
    (state) => state.avisZoneStoredBreadcumbRoutes
  );

  const onePriceStoredBreadcumbRoutes = useSolutionStore(
    (state) => state.onePriceStoredBreadcumbRoutes
  );

  const fenixStoredBreadcumbRoutes = useSolutionStore(
    (state) => state.fenixStoredBreadcumbRoutes
  );

  const updateBreadcumbRoute = useSolutionStore(
    (state) => state.updateBreadcumbRoute
  );

  const updateApoBreadcumbRoute = useSolutionStore(
    (state) => state.updateApoBreadcumbRoute
  );

  const updateLabellingBreadcumbRoute = useSolutionStore(
    (state) => state.updateLabellingBreadcumbRoute
  );

  const updateAvisBreadcumbRoute = useSolutionStore(
    (state) => state.updateAvisBreadcumbRoute
  );

  const updateAvisZoneBreadcumbRoute = useSolutionStore(
    (state) => state.updateAvisZoneBreadcumbRoute
  );

  const updateOnePriceBreadcumbRoute = useSolutionStore(
    (state) => state.updateOnePriceBreadcumbRoute
  );

  const updateFenixBreadcumbRoute = useSolutionStore(
    (state) => state.updateFenixBreadcumbRoute
  );

  const fidToRecover = useMemo(() => {
    if (isNil(process)) {
      return "-";
    } else {
      if (process === APOLLO_FRONT_ONLY_IDENTIFIER) {
        if (
          isNil(url) ||
          isNil(apolloStoredBreadcumbRoutes) ||
          (!isNil(apolloStoredBreadcumbRoutes) &&
            !isMap(apolloStoredBreadcumbRoutes)) ||
          (!isNil(apolloStoredBreadcumbRoutes) &&
            isMap(apolloStoredBreadcumbRoutes) &&
            apolloStoredBreadcumbRoutes.size === 0)
        ) {
          return "-";
        }
        const itemValue = apolloStoredBreadcumbRoutes.get(url);

        return !isNil(itemValue) && !isNil(itemValue.formInstanceId)
          ? itemValue.formInstanceId
          : "-";
      } else if (process === LABELLING_PROCESS_PARAM) {
        if (
          isNil(url) ||
          isNil(labellingStoredBreadcumbRoutes) ||
          (!isNil(labellingStoredBreadcumbRoutes) &&
            !isMap(labellingStoredBreadcumbRoutes)) ||
          (!isNil(labellingStoredBreadcumbRoutes) &&
            isMap(labellingStoredBreadcumbRoutes) &&
            labellingStoredBreadcumbRoutes.size === 0)
        ) {
          return "-";
        }
        const itemValue = labellingStoredBreadcumbRoutes.get(url);

        return !isNil(itemValue) && !isNil(itemValue.formInstanceId)
          ? itemValue.formInstanceId
          : "-";
      } else if (process === AVIS_LOCAL_PROCESS_PARAM) {
        if (
          isNil(url) ||
          isNil(avisStoredBreadcumbRoutes) ||
          (!isNil(avisStoredBreadcumbRoutes) &&
            !isMap(avisStoredBreadcumbRoutes)) ||
          (!isNil(avisStoredBreadcumbRoutes) &&
            isMap(avisStoredBreadcumbRoutes) &&
            avisStoredBreadcumbRoutes.size === 0)
        ) {
          return "-";
        }
        const itemValue = avisStoredBreadcumbRoutes.get(url);
        return !isNil(itemValue) && !isNil(itemValue.formInstanceId)
          ? itemValue.formInstanceId
          : "-";
      } else if (process === AVIS_ZONE_PROCESS_PARAM) {
        if (
          isNil(url) ||
          isNil(avisZoneStoredBreadcumbRoutes) ||
          (!isNil(avisZoneStoredBreadcumbRoutes) &&
            !isMap(avisZoneStoredBreadcumbRoutes)) ||
          (!isNil(avisZoneStoredBreadcumbRoutes) &&
            isMap(avisZoneStoredBreadcumbRoutes) &&
            avisZoneStoredBreadcumbRoutes.size === 0)
        ) {
          return "-";
        }
        const itemValue = avisZoneStoredBreadcumbRoutes.get(url);
        return !isNil(itemValue) && !isNil(itemValue.formInstanceId)
          ? itemValue.formInstanceId
          : "-";
      } else if (process === ONEPRICE_PROCESS_PARAM) {
        if (
          isNil(url) ||
          isNil(onePriceStoredBreadcumbRoutes) ||
          (!isNil(onePriceStoredBreadcumbRoutes) &&
            !isMap(onePriceStoredBreadcumbRoutes)) ||
          (!isNil(onePriceStoredBreadcumbRoutes) &&
            isMap(onePriceStoredBreadcumbRoutes) &&
            onePriceStoredBreadcumbRoutes.size === 0)
        ) {
          return "-";
        }
        const itemValue = onePriceStoredBreadcumbRoutes.get(url);
        return !isNil(itemValue) && !isNil(itemValue.formInstanceId)
          ? itemValue.formInstanceId
          : "-";
      } else if (process === FENIX_PROCESS_PARAM) {
        if (
          isNil(url) ||
          isNil(fenixStoredBreadcumbRoutes) ||
          (!isNil(fenixStoredBreadcumbRoutes) &&
            !isMap(fenixStoredBreadcumbRoutes)) ||
          (!isNil(fenixStoredBreadcumbRoutes) &&
            isMap(fenixStoredBreadcumbRoutes) &&
            fenixStoredBreadcumbRoutes.size === 0)
        ) {
          return "-";
        }
        const itemValue = fenixStoredBreadcumbRoutes.get(url);
        return !isNil(itemValue) && !isNil(itemValue.formInstanceId)
          ? itemValue.formInstanceId
          : "-";
      } else {
        if (
          isNil(url) ||
          isNil(storedBreadcumbRoutes) ||
          (!isNil(storedBreadcumbRoutes) && !isMap(storedBreadcumbRoutes)) ||
          (!isNil(storedBreadcumbRoutes) &&
            isMap(storedBreadcumbRoutes) &&
            storedBreadcumbRoutes.size === 0)
        ) {
          return "-";
        }
        const itemValue = storedBreadcumbRoutes.get(url);

        return !isNil(itemValue) && !isNil(itemValue.formInstanceId)
          ? itemValue.formInstanceId
          : "-";
      }
    }

    // eslint-disable-next-line
  }, [url]);

  //Handling form status variations
  useEffect(() => {
    if (!isNil(formStatus) && !isEmpty(formStatus)) {
      setFormData(formStatus);
      setInitialFormData(formStatus);
    }
  }, [formStatus]);

  useEffect(() => {
    if (!isNil(fidToRecover)) {
      fetchFormSchemaFromServer({
        ukey,
        path,
        idForm,
        values,
        process,
        fidToRecover,
        openFormLikeRecordsPanel,
      });
    }

    //eslint-disable-next-line
  }, [fidToRecover]);

  /**
   * Toggles loading indicator with new value, or the actual opposite if not parameter is received
   * @param {Boolean} to
   */
  const toggleLoading = useCallback((to) => {
    if (to !== null && to !== undefined) {
      setLoading(to);
    } else {
      setLoading((prev) => !prev);
    }
  }, []);

  /**
   * Changes actual tab of form
   * @param {*} event
   * @param {*} newTab
   */
  function changeFormTab(event, newTab) {
    changeGridLayout(gridLayout);
    setSelectedTab(newTab);
  }

  /**
   * Change grid layout
   * @param {String} newGL
   */
  function changeGridLayout(newGL) {
    setGridLayout(newGL);
  }

  /**
   * Get operation text based on actual form operation setted in Graph
   * @returns
   */
  function getOperationText() {
    if (actualOperation === FORM_OPERATION_NEW) {
      return t("TABLE_PANEL_NEW");
    } else if (actualOperation === FORM_OPERATON_EDIT) {
      return t("TABLE_PANEL_DECISION_EDIT");
    } else if (actualOperation === FORM_OPERATION_DELETE) {
      return t("TABLE_PANEL_DECISION_DELETE");
    } else if (actualOperation === FORM_OPERATION_VIEW) {
      return t("FORM_VIEW");
    } else {
      return "";
    }
  }

  /**
   * Get operation icon based on actual form operation setted in Graph
   * @returns
   */
  function getFasIcon() {
    if (actualOperation === FORM_OPERATION_NEW) {
      return "fas fa-check-double";
    } else if (actualOperation === FORM_OPERATON_EDIT) {
      return "fas fa-edit";
    } else if (actualOperation === FORM_OPERATION_DELETE) {
      return "fas fa-trash";
    } else if (actualOperation === FORM_OPERATION_VIEW) {
      return "fas fa-eye";
    } else {
      return "";
    }
  }

  /**
   * Get operation description based on actual form operation setted in Graph
   * @returns
   */
  function getDescText() {
    if (actualOperation === FORM_OPERATION_NEW) {
      return t("FORM_SAVE");
    } else if (actualOperation === FORM_OPERATON_EDIT) {
      return t("FORM_EDIT");
    } else if (actualOperation === FORM_OPERATION_DELETE) {
      return t("FORM_DELETE");
    } else if (actualOperation === FORM_OPERATION_VIEW) {
      return t("FORM_VIEW");
    } else {
      return "";
    }
  }

  /**
   * Call server to change operation in Graph and then changes operation in form
   * @param {String} newOperation
   */
  async function changeOperation(newOperation) {
    const response = await notifyServerChangeOperation(
      {
        formInstanceId,
        operation: newOperation,
      },
      REQUEST_HEADERS
    );
    const { ok, msg } = response;

    if (ok) {
      setActualOperation(newOperation);
    } else {
      //Throw notification
      toast.error(msg, {
        containerId: TOAST_CONTAINER_LAYOUT,
        toastId: "changeOperationError",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const audit = useMemo(() => {
    return {
      ...deviceInfo,
      userName: auth?.userName,
      clientDateMod: format(new Date(), DATE_TIME_PATTERN),
      operation,
      tableName,
      solution,
    };
  }, [auth, deviceInfo, operation, solution, tableName]);

  //In charge of calling server to get Form definition
  async function fetchFormSchemaFromServer(params) {
    //toggleLoading(true);
    if (loading) {
      const response = await callOpenExternalForm(
        params,
        REQUEST_HEADERS,
        audit
      );
      await analyzeFetchFormSchemaFromServerResponse(response);
      toggleLoading(false);
    }
  }

  const updateBreadcumbRouteCallback = useCallback(
    (url, formInstanceId) => {
      if (!isNil(process)) {
        switch (process) {
          case APOLLO_FRONT_ONLY_IDENTIFIER:
            updateApoBreadcumbRoute(url, { formInstanceId });
            break;
          case LABELLING_PROCESS_PARAM:
            updateLabellingBreadcumbRoute(url, { formInstanceId });
            break;
          case AVIS_LOCAL_PROCESS_PARAM:
            updateAvisBreadcumbRoute(url, { formInstanceId });
            break;
          case AVIS_ZONE_PROCESS_PARAM:
            updateAvisZoneBreadcumbRoute(url, { formInstanceId });
            break;
          case ONEPRICE_PROCESS_PARAM:
            updateOnePriceBreadcumbRoute(url, { formInstanceId });
            break;
          case FENIX_PROCESS_PARAM:
            updateFenixBreadcumbRoute(url, { formInstanceId });
            break;
          default:
            updateBreadcumbRoute(url, { formInstanceId });
            break;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [process]
  );

  // Analyze Server response when fetch form
  async function analyzeFetchFormSchemaFromServerResponse(response) {
    let toastContent = null;

    if (isNil(response)) {
      toastContent = (
        <CustomBodyToast
          msg={null}
          title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
        />
      );
    } else {
      //Destructuring and renaming
      const {
        ok,
        msg,
        _formInstanceId: formInstanceId,
        formStatus,
        form,
        status,
      } = response;

      //Handling server response method getForm
      if (status === HTTP_STATUS_UNAUTHORIZED) {
        logout(true);
      } else if (status === HTTP_STATUS_NOT_FOUND) {
        toastContent = (
          <CustomBodyToast
            msg={null}
            title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
          />
        );
        //setLoading(false);
        toggleLoading(false);
      } else {
        if (ok) {
          updateBreadcumbRouteCallback(url, formInstanceId);
          setFormInstanceId(formInstanceId);
          const transformedFormStatus =
            transformFormStatusFromServer(formStatus);
          setShadowStatus(formStatus);
          setFormStatus(transformedFormStatus);

          const { formConfig } = transformedFormStatus;

          if (!isNil(formConfig)) {
            setFormConfig(formConfig);
          }

          if (form) {
            setFormSchema(form);
            setGridLayout(form?.layout);
            // setTimeout(() => {
            //   setLoading(false);
            // }, 50);
            toggleLoading(false);
          } else {
            toggleLoading(false);
            //setLoading(false);
          }
        } else {
          setFormSchema(null);
          //setLoading(false);
          toggleLoading(false);
          toastContent = <CustomBodyToast msg={null} title={msg} />;
        }
      }
    }

    if (toastContent !== null) {
      //Throw toast
      toast.error(toastContent, {
        containerId: TOAST_CONTAINER_LAYOUT,
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  /**
   * In charge of update shadow status to check if value has changed
   * @param {*} newShadowStatus
   */
  function updateShadowStatus(newShadowStatus) {
    setShadowStatus(newShadowStatus);
  }

  const jasperReports = useMemo(() => {
    if (!isNil(formSchema) && !isNil(formSchema.jasper)) {
      return formSchema.jasper;
    } else {
      return [];
    }
  }, [formSchema]);

  async function handleJasperReport(jasperParams) {
    setGeneratingJasper(true);
    let toastContent = null;
    const response = await callExportJasper(
      {
        path,
        idForm,
        formInstanceId,
        ...jasperParams,
      },
      REQUEST_HEADERS
    );
    if (response) {
      const { ok, errorMsg, dataResponse, status } = response;
      if (status === HTTP_STATUS_UNAUTHORIZED) {
        logout(true);
      } else if (ok && errorMsg) {
        toast.info(errorMsg, {
          containerId: TOAST_CONTAINER_LAYOUT,
          position: "top-right",
          autoClose: 5000,
        });
      } else if (ok && dataResponse) {
        const { url, reportName } = dataResponse;
        if (url && reportName) {
          saveAs(url, reportName);
        }
      } else {
        toastContent = (
          <CustomBodyToast
            msg={null}
            title={errorMsg || t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
          />
        );
      }
    } else {
      toastContent = (
        <CustomBodyToast
          msg={null}
          title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
        />
      );
    }

    if (toastContent !== null) {
      toast.error(toastContent, {
        containerId: TOAST_CONTAINER_LAYOUT,
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    handleCloseJasperOptions();
  }

  const handleJasperOption = (event) => {
    setAnchorElJasperOpt(event.currentTarget);
  };

  const handleCloseJasperOptions = () => {
    setAnchorElJasperOpt(null);
    setGeneratingJasper(false);
  };

  function moveToAnotherSectionByIndex(newSectionIndex) {
    setSelectedTab(newSectionIndex);
  }

  return {
    formData,
    setFormData,
    initialFormData,
    loading,
    formSchema,
    formStatus,
    setFormStatus,
    formInstanceId,
    toggleLoading,
    gridLayout,
    changeGridLayout,
    actualOperation,
    changeOperation,
    getDescText,
    getFasIcon,
    getOperationText,
    changeFormTab,
    selectedTab,
    executedAction,
    setExecutedAction,
    shadowStatus,
    updateShadowStatus,
    jasperReports,
    handleJasperReport,
    handleJasperOption,
    handleCloseJasperOptions,
    anchorElJasperOpt,
    generatingJasper,
    moveToAnotherSectionByIndex,
    formConfig,
  };
}
