import create from "zustand";
import {
  isNil,
  isEmpty,
  isArray,
  isMap,
  clone,
  replace,
  isFunction,
  find,
  isString,
  uniq,
  includes,
  isObject,
} from "lodash";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { toast } from "react-toastify";

import { isJsonFormat } from "../../util/UtilFormat";
import {
  API_FORM_QUERY,
  HTTP_STATUS_UNAUTHORIZED,
  METHOD_POST,
  SERVER_HOST,
  TOAST_CONTAINER_LAYOUT,
  TOAST_ID_SERVER_OFF,
} from "../../util/Constants";

const MOUNT_DEVTOOL = true;
const DEFAULT_VIEW_SUFFIX = "base";

const EMPTY_VIEWS_CONSTANT = "empty-views";

function convertArrayToMap(arr) {
  if (!isNil(arr) && !isEmpty(arr) && isArray(arr)) {
    return new Map(
      arr.map((object) => {
        return [object.key, object.value];
      })
    );
  }

  if (!isNil(arr) && !isEmpty(arr) && isMap(arr)) {
    return arr;
  }

  return new Map();
}

function convertMapToArray(map) {
  if (!isNil(map) && isArray(map)) {
    return map;
  }

  return Array.from(map, function (i) {
    return { key: i[0], value: i[1] };
  });
}

function getMapWithKeysNotContainingText(map, pattern) {
  if (
    !isNil(map) &&
    !isEmpty(map) &&
    isMap(map) &&
    !isNil(pattern) &&
    !isEmpty(pattern)
  ) {
    map.forEach((value, key) => {
      if (key.startsWith(pattern)) {
        map.delete(key);
      }
    });
  }
  return map;
}

export function getKeyForMaps({ keyTable, selectedView }) {
  if (!isNil(selectedView) && !isNil(selectedView.key)) {
    return `${keyTable}-${selectedView.key}`;
  } else {
    return `${keyTable}-${DEFAULT_VIEW_SUFFIX}`;
  }
}

const useReportsStore = create((set, get) => ({
  sColumns: new Map(),
  sColumnsOrder: new Map(),
  sGroupBy: new Map(),
  sFilters: new Map(),
  sGlobalFilters: new Map(),
  sHiddenColumns: new Map(),
  selectedReportTab: new Map(),
  viewsByReport: new Map(),
  selectedViewReport: new Map(),
  savingView: false,
  selColsOpened: {
    open: false,
    elem: null,
  },
  sPageSize: new Map(),
  executingGlobalAction: false,
  changeReport: ({ report }) => {
    const selectedViewReport = get().selectedViewReport;
    const viewsByReport = get().viewsByReport;

    if (selectedViewReport.has(report)) {
      const selectedViewKeyToSet = selectedViewReport.get(report).key;
      if (viewsByReport.has(report)) {
        const viewToSet = find(viewsByReport.get(report), {
          key: selectedViewKeyToSet,
        });
        const applyReportViewConfig = get().applyReportViewConfig;
        if (
          !isNil(applyReportViewConfig) &&
          isFunction(applyReportViewConfig)
        ) {
          applyReportViewConfig({
            report,
            view: viewToSet,
          });
        }
      }
    }
  },
  modifyColumns: ({ key, newColumns }) => {
    const actualMap = get().sColumns;
    actualMap.delete(key);
    actualMap.set(key, newColumns);
    set({ sColumns: actualMap });
  },
  modifyColumnsOrder: ({ key, newColumnsOrder }) => {
    const actualMap = get().sColumnsOrder;
    actualMap.delete(key);
    actualMap.set(key, newColumnsOrder);
    set({ sColumnsOrder: actualMap });
  },
  modifyGroupBy: ({ key, newGroupBy }) => {
    const actualMap = get().sGroupBy;
    actualMap.delete(key);
    actualMap.set(key, newGroupBy);
    set({ sGroupBy: actualMap });
  },
  modifyFilters: ({ key, newFilters }) => {
    const actualMapFilters = get().sFilters;
    actualMapFilters.delete(key);
    actualMapFilters.set(key, newFilters);
    set({ newFilters: actualMapFilters });
  },
  modifyGlobalFilters: ({ key, newGlobalFilters }) => {
    if (!isNil(newGlobalFilters) && !isEmpty(newGlobalFilters)) {
      if (
        isObject(newGlobalFilters) &&
        !isNil(newGlobalFilters.queryFilters) &&
        isArray(newGlobalFilters.queryFilters)
      ) {
        const actualMapGlobalFilters = get().sGlobalFilters;
        actualMapGlobalFilters.delete(key);
        actualMapGlobalFilters.set(key, newGlobalFilters.queryFilters);
        set({ sGlobalFilters: actualMapGlobalFilters });
      } else if (isArray(newGlobalFilters)) {
        const actualMapGlobalFilters = get().sGlobalFilters;
        actualMapGlobalFilters.delete(key);
        actualMapGlobalFilters.set(key, newGlobalFilters);
        set({ sGlobalFilters: actualMapGlobalFilters });
      } else {
        // ???
      }
    }
  },
  toggleHiddenColumns: ({ key, hiddenColumns }) => {
    const sHiddenColumnsMap = get().sHiddenColumns;
    sHiddenColumnsMap.delete(key);
    const uniqueHC = new Set(hiddenColumns);
    sHiddenColumnsMap.set(key, Array.from(uniqueHC));
    set(() => ({
      sHiddenColumns: sHiddenColumnsMap,
    }));
  },
  changeSelectedReportTab: ({ key, newTab }) => {
    const actualMap = get().selectedReportTab;
    actualMap.delete(key);
    actualMap.set(key, newTab);
    set({ selectedReportTab: actualMap });
  },
  setSelColsOpened: (newState) => {
    set(() => ({ selColsOpened: newState }));
  },
  applyReportViewConfig: ({ report, view }) => {
    if (!isNil(view) && !isNil(view.key) && !isNil(view.config)) {
      const viewConfig = view.config;
      const acutlaMapSelectedReportTab = get().selectedReportTab;
      acutlaMapSelectedReportTab.delete(report);
      acutlaMapSelectedReportTab.set(report, 0);

      const actualMapSelectedViewByReport = get().selectedViewReport;
      actualMapSelectedViewByReport.delete(report);
      actualMapSelectedViewByReport.set(report, view);

      const sGroupByToSet = convertArrayToMap(viewConfig?.sGroupBy);
      const sColumnsToSet = convertArrayToMap(viewConfig?.sColumns);
      const sHiddenColumnsToSet = convertArrayToMap(viewConfig?.sHiddenColumns);
      const sGlobalFiltersToSet = convertArrayToMap(viewConfig?.sGlobalFilters);
      const sColumnsOrderToSet = convertArrayToMap(viewConfig?.sColumnsOrder);

      set(() => ({
        sGroupBy: sGroupByToSet,
        sColumns: sColumnsToSet,
        sHiddenColumns: sHiddenColumnsToSet,
        selectedReportTab: acutlaMapSelectedReportTab,
        selectedViewReport: actualMapSelectedViewByReport,
        sGlobalFilters: sGlobalFiltersToSet,
        sColumnsOrder: sColumnsOrderToSet,
      }));
    }
  },
  saveViewReport: async ({ report, viewKey, t, REQUEST_HEADERS, logout }) => {
    const sColumnsMap = get().sColumns;
    const sGroupByMap = get().sGroupBy;
    const sHiddenColumns = get().sHiddenColumns;
    const sGlobalFilters = get().sGlobalFilters;
    const sColumnsOrder = get().sColumnsOrder;

    if (!isNil(report) && !isNil(viewKey)) {
      const actualViewsByReport = get().viewsByReport;
      if (!isNil(actualViewsByReport) && actualViewsByReport.has(report)) {
        const actualArrayViews = actualViewsByReport.get(report);
        const newArrayToSet = actualArrayViews.map((x) => {
          if (x?.key === viewKey) {
            const hiddenColumnsToSet = convertMapToArray(sHiddenColumns);
            return {
              ...x,
              config: {
                sColumns: convertMapToArray(sColumnsMap),
                sGroupBy: convertMapToArray(sGroupByMap),
                sHiddenColumns:
                  !isNil(hiddenColumnsToSet) && !isEmpty(hiddenColumnsToSet)
                    ? hiddenColumnsToSet.map((x) => {
                        return {
                          key: x?.key,
                          value:
                            !isNil(x?.value) && !isEmpty(x.value)
                              ? uniq(x.value)
                              : x?.value,
                        };
                      })
                    : hiddenColumnsToSet,
                sGlobalFilters: convertMapToArray(sGlobalFilters),
                sColumnsOrder: convertMapToArray(sColumnsOrder),
              },
            };
          }
          return x;
        });
        actualViewsByReport.set(report, newArrayToSet);
        const notifyServerSaveQueryView = get().notifyServerSaveQueryView;
        if (
          !isNil(notifyServerSaveQueryView) &&
          isFunction(notifyServerSaveQueryView)
        ) {
          await notifyServerSaveQueryView({
            report,
            newArrayToSet,
            t,
            REQUEST_HEADERS,
            logout,
          });
        }
      }
    }
  },
  clearSelectedViewReport: ({ report }) => {
    const actualSelectedViewReport = get().selectedViewReport;
    if (
      !isNil(report) &&
      !isNil(actualSelectedViewReport) &&
      actualSelectedViewReport.has(report)
    ) {
      actualSelectedViewReport.delete(report);

      const acutlaMapSelectedReportTab = get().selectedReportTab;
      acutlaMapSelectedReportTab.delete(report);
      acutlaMapSelectedReportTab.set(report, 0);

      set(() => ({
        selectedViewReport: actualSelectedViewReport,
        sColumns: new Map(),
        sGroupBy: new Map(),
        selectedReportTab: acutlaMapSelectedReportTab,
        sHiddenColumns: new Map(),
        sGlobalFilters: new Map(),
        sColumnsOrder: new Map(),
      }));
    }
  },
  deleteViewReport: async ({ report, viewKey, t, REQUEST_HEADERS, logout }) => {
    if (!isNil(report) && !isNil(viewKey)) {
      const actualViewsByReport = get().viewsByReport;
      if (!isNil(actualViewsByReport) && actualViewsByReport.has(report)) {
        const actualViews = clone(actualViewsByReport.get(report));
        if (!isNil(actualViews) && !isEmpty(actualViews)) {
          const newActualViews = actualViews.filter((x) => x?.key !== viewKey);
          const actualSelectedViewReport = get().selectedViewReport;
          actualSelectedViewReport.delete(report);
          if (!isEmpty(newActualViews)) {
            actualViewsByReport.set(report, newActualViews);
            set(() => ({
              viewsByReport: actualViewsByReport,
              selectedViewReport: actualSelectedViewReport,
              sColumns: new Map(),
              sGroupBy: new Map(),
            }));
          } else {
            actualViewsByReport.delete(report);
            set(() => ({
              viewsByReport: actualViewsByReport,
              selectedViewReport: actualSelectedViewReport,
              sColumns: new Map(),
              sGroupBy: new Map(),
            }));
          }
          //notify server to delete that view in db
          const notifyServerSaveQueryView = get().notifyServerSaveQueryView;
          if (
            !isNil(notifyServerSaveQueryView) &&
            isFunction(notifyServerSaveQueryView)
          ) {
            await notifyServerSaveQueryView({
              report,
              newArrayToSet: actualViewsByReport.get(report),
              t,
              REQUEST_HEADERS,
              logout,
            });
          }
        }
      }
    }
  },
  createNewView: async ({
    report,
    viewKey,
    viewName,
    copyView,
    t,
    REQUEST_HEADERS,
    logout,
  }) => {
    const actualViewsByReport = get().viewsByReport;
    const sColumnsMap = get().sColumns;
    const sGroupByMap = get().sGroupBy;
    const sHiddenColumns = get().sHiddenColumns;
    const sGlobalFilters = get().sGlobalFilters;
    const sColumnsOrder = get().sColumnsOrder;

    const calculatedViewKeyToCopy =
      !isNil(copyView) && !isEmpty(copyView) ? copyView : DEFAULT_VIEW_SUFFIX;

    const newView = {
      key: viewKey,
      name: viewName,
      config: {
        sColumns: convertMapToArray(sColumnsMap).map((x) => {
          return {
            ...x,
            key: replace(x.key, calculatedViewKeyToCopy, viewKey),
          };
        }),
        sGroupBy: convertMapToArray(sGroupByMap).map((x) => {
          return {
            ...x,
            key: replace(x.key, calculatedViewKeyToCopy, viewKey),
          };
        }),
        sHiddenColumns: convertMapToArray(sHiddenColumns).map((x) => {
          return {
            ...x,
            key: replace(x.key, calculatedViewKeyToCopy, viewKey),
          };
        }),
        sGlobalFilters: convertMapToArray(sGlobalFilters).map((x) => {
          return {
            ...x,
            key: replace(x.key, calculatedViewKeyToCopy, viewKey),
          };
        }),
        sColumnsOrder: convertMapToArray(sColumnsOrder).map((x) => {
          return {
            ...x,
            key: replace(x.key, calculatedViewKeyToCopy, viewKey),
          };
        }),
      },
    };

    if (
      !isNil(actualViewsByReport) &&
      !isEmpty(actualViewsByReport) &&
      actualViewsByReport.has(report)
    ) {
      const actualViewsInReport = actualViewsByReport.get(report);
      const viewsToSet = [...actualViewsInReport, newView];
      actualViewsByReport.set(report, viewsToSet);
    } else {
      actualViewsByReport.set(report, [newView]);
    }

    const notifyServerSaveQueryView = get().notifyServerSaveQueryView;
    if (
      !isNil(notifyServerSaveQueryView) &&
      isFunction(notifyServerSaveQueryView)
    ) {
      await notifyServerSaveQueryView({
        report,
        newArrayToSet: actualViewsByReport.get(report),
        t,
        REQUEST_HEADERS,
        logout,
      });
    }

    set(() => ({
      viewsByReport: actualViewsByReport,
    }));

    return newView;
  },
  setViewsByReport: ({ report, views }) => {
    if (!isNil(report) && !isNil(views)) {
      const viewsToSet =
        isString(views) && isJsonFormat(views) ? JSON.parse(views) : views;
      const actualViewsInReport = get().viewsByReport;
      actualViewsInReport.delete(report);
      actualViewsInReport.set(report, viewsToSet);
      set({ viewsByReport: actualViewsInReport });
    }
  },
  notifyServerSaveQueryView: async ({
    report,
    newArrayToSet,
    t,
    REQUEST_HEADERS,
    logout,
  }) => {
    set({ savingView: true });
    const url = SERVER_HOST() + API_FORM_QUERY + `/view/save?query=${report}`;

    const bodyToSend =
      !isNil(newArrayToSet) && !isEmpty(newArrayToSet)
        ? JSON.stringify(newArrayToSet)
        : EMPTY_VIEWS_CONSTANT;

    const response = await fetch(url, {
      method: METHOD_POST,
      headers: REQUEST_HEADERS,
      body: bodyToSend,
    })
      .then((res) => res.json())
      .then((jsonResult) => {
        return jsonResult;
      })
      .catch((err) => {
        return err;
      });

    if (
      response?.status === HTTP_STATUS_UNAUTHORIZED &&
      !isNil(logout) &&
      isFunction(logout)
    ) {
      logout(true);
    } else {
      if (!isNil(response)) {
        const { ok, errorMsg } = response;
        if (!ok) {
          toast.error(`${errorMsg}`, {
            containerId: TOAST_CONTAINER_LAYOUT,
            autoClose: 5000,
            closeOnClick: false,
          });
        }
      } else {
        toast.error(t("ERROR_RESOURCE_NOT_FOUND_TEXT"), {
          containerId: TOAST_CONTAINER_LAYOUT,
          toastId: TOAST_ID_SERVER_OFF,
          autoClose: 5000,
          closeOnClick: false,
        });
      }
    }

    set({ savingView: false });
  },
  removeReportData: (params) => {
    if (
      !isNil(params) &&
      !isEmpty(params) &&
      !isNil(params.route) &&
      isString(params.route) &&
      includes(params.route, "consultas")
    ) {
      const route = params?.route;
      const report = route.substring(route.lastIndexOf("/") + 1);
      const pattern = `${report}-`;

      set((state) => ({
        sColumns: getMapWithKeysNotContainingText(state.sColumns, pattern),
        sGroupBy: getMapWithKeysNotContainingText(state.sGroupBy, pattern),
        sFilters: getMapWithKeysNotContainingText(state.sFilters, pattern),
        sGlobalFilters: getMapWithKeysNotContainingText(
          state.sGlobalFilters,
          pattern
        ),
        sHiddenColumns: getMapWithKeysNotContainingText(
          state.sHiddenColumns,
          pattern
        ),
        selectedReportTab: getMapWithKeysNotContainingText(
          state.selectedReportTab,
          pattern
        ),
        viewsByReport: getMapWithKeysNotContainingText(
          state.viewsByReport,
          pattern
        ),
        selectedViewReport: getMapWithKeysNotContainingText(
          state.selectedViewReport,
          pattern
        ),
      }));
    }
  },
  modifyPageSize: ({ key, newPageSize }) => {
    const actualMap = get().sPageSize;
    actualMap.delete(key);
    actualMap.set(key, newPageSize);
    set({ sPageSize: actualMap });
  },
  clearAllReportsStore: () => {
    set(() => ({
      sColumns: new Map(),
      sColumnsOrder: new Map(),
      sGroupBy: new Map(),
      sFilters: new Map(),
      sGlobalFilters: new Map(),
      sHiddenColumns: new Map(),
      selectedReportTab: new Map(),
      viewsByReport: new Map(),
      selectedViewReport: new Map(),
      savingView: false,
      selColsOpened: {
        open: false,
        elem: null,
      },
      sPageSize: new Map(),
      executingGlobalAction: false,
    }));
  },
}));

export { useReportsStore };

if (process.env.NODE_ENV === "development" && MOUNT_DEVTOOL) {
  mountStoreDevtool("ReportsStore", useReportsStore);
}
