import React, {
  useState,
  useMemo,
  useContext,
  useRef,
  useEffect,
  useCallback,
} from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Form from "@rjsf/core";
import { Responsive as ResponsiveGridLayout,} from "react-grid-layout";
import BlockUi from "react-block-ui";
import { useHistory, useLocation } from "react-router-dom";
import {
  Box,
  Divider,
  Hidden,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as _ from "lodash";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import { format } from "date-fns";
import { SizeMe } from "react-sizeme";
import { v4 as uuid } from "uuid";
import { saveAs } from "file-saver";

//Own imports
import { customSimpleFields } from "../customSimpleFields/fields";
import * as Constants from "../../../util/Constants";
import BadForm from "../../../components/badForm/BadForm";
import {
  getHTTPMethodByOperaton,
  makeUniqueFormID,
  fetchFormSchemaFromServer,
  transformFormStatusFromServer,
  callServerToSetValue,
  isServerResponseValid,
  extractErrorMessageFromResponse,
  returnFocusToRef,
  makeErrorMapToSet,
  controlHasError,
  confirmForm,
  notifyServerChangeOperation,
  valueHasChanged,
  callExportJasper,
  resolveValuesFieldAgainsShadowStatus,
  resolveFieldAgainstShadowStatus,
  notifyServerCleanFormInstanceId,
} from "../../../util/UtilForm";
import { DeviceContext } from "../../../core/providers/DeviceContext";
import { AuthContext } from "../../../core/providers/AuthContext";

import { useStyles, commonIcon } from "./FormContainerStyles";

import useWindowDimensions from "../../../core/hooks/useWindowDimensions";
import NothingToShow from "../../nothingToShow/NothingToShow";
import DeleteFormConfirmDialog from "../../dialogs/deleteFormConfirm/DeleteFormConfirmDialog";
import { LayoutContext } from "../../../core/providers/LayoutContext";
import GridTable from "../customTable/container/GridTableCustom";
import CustomBodyToast from "../../toast/CustomBodyToast";
import "../../../shared/styles/react-block-ui/ReactBlock.css";
import SimpleBlock from "../blocks/simple/SimpleBlock";
import TrackingServicesDialog from "../../dialogs/trackingServicesDialog/TrackingServicesDialog";
import { KEY_T } from "../../../util/UtilKeys";
import { clientServices } from "../clientServices/services";

import * as formAPI from "./api";
import BreakpointChooser from "../inner/BreakpointChooser";
import { useRequestHeaders } from "../../../core/hooks/useRequestHeaders";
import useNormalForm from "../../../core/hooks/useNormalForm";
import { useBlockingToast } from "../../../core/hooks/useBlockingToast";

import { useInteractiveItemStore } from "../../../core/stores/InteractiveItemStore";
import CloseSubTabDialog from "../../../shared/layout/components/tabs/dialogs/CloseSubTabDialog";
import SubTabsContainer from "../../../shared/layout/components/tabs/SubTabsContainer";
import HelpDialog from "../../dialogs/helpDialog/HelpDialog";
import GenericToastContainer from "../../toast/GenericToastContainer";
import useWizardStore from "../../../core/stores/WizardStore";
import { BadgeWizardStepSection } from "../blocks/simple/BadgeWizardStepSection";
import { useFeatureOnSolutions } from "../../../core/hooks/useFeatureOnSolutions";

export const ID_FORM_CONTAINER_ACTION_BUTTON = "form_container_action_button";

export default function FormContainer({ operation }) {
  const {
    activeTabItem,
    activeSubItem,
    activeSubItemObject,
    initialFormState,
    saveActiveSubItem,
    setInitialformState,
    removeActiveSubItemObjectByFid,
    removeSubTabItem,
    clearActiveSubItem,
    updateFormOperationInStore,
  } = useInteractiveItemStore();

  const toastId = useRef(null);
  const { t } = useTranslation();
  let {
    pathname,
    state: {
      idForm,
      path,
      key,
      oldRoute,
      entityName,
      tableName,
      prevRecordsPanelState,
      loopNewEnabled = true,
      solution,
      count_number,
    } = {},
  } = useLocation();

  const location = useLocation();

  const theme = useTheme();
  const history = useHistory();
  const { auth, logout, renewToken } = useContext(AuthContext);
  const { deviceInfo } = useContext(DeviceContext);

  const formContentContainerRef = useRef();

  const [alreadyDone, isAlreadyDone] = useState(false);
  const [fetching, isFetching] = useState(false);
  const [loading, isLoading] = useState(true);
  const [errorMap, setErrorMap] = useState([]);
  const [deleteFormConfirmation, setDeleteFormConfirmation] = useState(false);
  const [actualOperation, setActualOperation] = useState(null);

  //FORM ONLY
  const [formData, setFormData] = useState();
  const [formSchema, setFormSchema] = useState(null);
  const [initialFormData, setInitialFormData] = useState();
  const [formInstanceId, setFormInstanceId] = useState(null);
  const [formStatus, setFormStatus] = useState(null);
  const [sortedSections, setSortedSections] = useState([]);
  const windowDimensions = useWindowDimensions();
  const [formConfig, setFormConfig] = useState(null);
  const [selectedBreak, setSelectedBreak] = useState(null);
  const [executedAction, setExecutedAction] = useState(false);
  const [isSettingValueInServer, setIsSettingValueInServer] = useState(false);
  const [showTrazability, setShowTrazability] = useState(false);
  const [openTSDialog, setOpenTSDialog] = useState(false);
  const [routeId, setRouteId] = useState(uuid());
  const [anchorElJasperOpt, setAnchorElJasperOpt] = useState(null);
  const [generatingJasper, setGeneratingJasper] = useState(false);

  const {
    changeTextHeader,
    changeLayoutBreakpoint,
    changeCanGoBack,
    showSubItemsInForm,
    changeShowSubItemsInForm,
    isDrawerOpen,
  } = useContext(LayoutContext);

  const classes = useStyles({ openDrawer: isDrawerOpen, wd: windowDimensions });

  const REQUEST_HEADERS = useRequestHeaders();

  const { renderBlockingToast, BlockingToastModal } = useBlockingToast();

  const {
    gridLayout,
    selectedTab,
    handleTabChange,
    getOperationText,
    getFasIcon,
    getDescText,
    handleChangeGridLayout,
    updateShadowStatus,
    shadowStatus,
    moveToAnotherSectionByIndex,
  } = useNormalForm({
    actualOperation,
  });

  const { shouldRenderBadge, removeBadge, disabledBlocks } = useWizardStore();

  const featureCleanAfterNew = useFeatureOnSolutions(
    Constants.NEW_CLEANING_AFTER_NEW_SOLUTIONS
  );

  const arrayBlocksDisabledByWizard =
    !_.isNil(disabledBlocks) &&
      !_.isEmpty(disabledBlocks) &&
      _.isMap(disabledBlocks) &&
      disabledBlocks.has(formInstanceId)
      ? disabledBlocks.get(formInstanceId)
      : [];

  useEffect(() => {
    changeShowSubItemsInForm(true);

    return () => changeShowSubItemsInForm(false);
    //eslint-disable-next-line
  }, []);

  //tariq
  useEffect(
    function () {
      if (initialFormState && _.isNil(formInstanceId)) {
        setFormData(initialFormState);
      }
    },
    //eslint-disable-next-line
    [activeSubItem]
  );

  useEffect(() => {
    if (actualOperation === null) {
      setActualOperation(operation);
    }
    changeCanGoBack(false);
  }, [actualOperation, operation, changeCanGoBack]);

  let formOperations = formSchema?.defaultPanelConfig?.formOperations || [];

  //In charge of fetch schema, formInstace and initial formStatus
  const initFecthForm = async (formInstanceIdToRecover, operationToRecover) => {
    const audit = {
      ...deviceInfo,
      userName: auth?.userName,

      clientDateMod: format(new Date(), Constants.DATE_TIME_PATTERN),
      operation,
      entityName,
      tableName,
      solution,
    };

    //Fetching server
    const getFormServerResponse = await fetchFormSchemaFromServer(
      path,
      idForm,
      operation,
      key,
      audit,
      REQUEST_HEADERS,
      formInstanceIdToRecover
    );

    let toastContent = null;

    if (getFormServerResponse) {
      const { status } = getFormServerResponse;

      if (status === Constants.HTTP_STATUS_UNAUTHORIZED) {
        logout(true);
      } else if (status === Constants.HTTP_STATUS_NOT_FOUND) {
        toastContent = (
          <CustomBodyToast
            msg={null}
            title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
          />
        );
      } else {
        //Handling server response method getForm
        if (
          !_.isNil(getFormServerResponse) &&
          !_.isNil(getFormServerResponse.failed) &&
          getFormServerResponse.failed === false
        ) {
          const { formInstanceId, formStatus, formSchema } =
            getFormServerResponse;

          setFormInstanceId(formInstanceId);
          const transformedFormStatus =
            transformFormStatusFromServer(formStatus);

          updateShadowStatus(formStatus);
          setFormStatus(transformedFormStatus);

          const { formConfig } = transformedFormStatus;

          if (!_.isNil(formConfig)) {
            setFormConfig(formConfig);
          }

          if (!_.isNil(operationToRecover)) {
            setActualOperation(operationToRecover);
          }

          if (formSchema) {
            if (!_.isNil(formSchema.error) && formSchema.error === true) {
              //ERROR
              isFetching(false);
              isLoading(false);
            } else {
              //OK
              if (!_.isNil(formSchema.form)) {
                const { form } = formSchema;

                setFormSchema(form);
                handleChangeGridLayout(form?.layout);
                setTimeout(() => {
                  isFetching(false);
                  isLoading(false);
                }, 50);
              } else {
                isFetching(false);
                isLoading(false);
              }
            }
          }
        } else {
          isFetching(false);
          isLoading(false);

          toastContent = (
            <CustomBodyToast
              msg={getFormServerResponse.msg}
              title={t("ERROR_OPENING_FORM")}
            />
          );
        }
      }
    } else {
      toastContent = (
        <CustomBodyToast
          msg={null}
          title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
        />
      );
    }

    if (toastContent !== null) {
      //Throw toast
      toastId.current = toast.error(toastContent, {
        containerId: Constants.TOAST_CONTAINER_FORM_CONTAINER,
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    isFetching(false);
    isLoading(false);
  };
  //Component will mount para llamar al encargado de recuperar el schema del formulario donde estoy parado
  useEffect(() => {
    setFormStatus(null);
    //console.log(`ejecuto use effect initFecthForm`);
    if (
      activeTabItem &&
      activeSubItem &&
      activeSubItem.has(activeTabItem) &&
      !_.isNil(activeSubItemObject) &&
      !_.isEmpty(activeSubItemObject)
    ) {
      const activeSubItemInMap = activeSubItem.get(activeTabItem);
      const keyToAsk = `${activeTabItem}_${activeSubItemInMap?.entityName}`;
      const currentSub = activeSubItemObject.find((x) => x.key === keyToAsk);
      if (_.isNil(currentSub) || _.isNil(currentSub?.formInstanceId)) {
        //console.log(`NUEVO CON ACTIVO`);
        initFecthForm();
      } else {
        //console.log(`VIEJO: ${currentSub?.formInstanceId}`);
        initFecthForm(currentSub?.formInstanceId, currentSub?.operation);
      }
    } else {
      //console.log(`NUEVO LIMPIO`);
      initFecthForm();
    }
    // eslint-disable-next-line
  }, [routeId, count_number]);

  useEffect(() => {
    //tariq
    if (
      activeSubItem &&
      activeSubItem.has(activeTabItem) &&
      activeSubItemObject.length >= 1
    ) {
      //check if it is, if not add
      let found = false;
      for (let row of activeSubItemObject) {
        const activeSubItemInMap = activeSubItem.get(activeTabItem);
        const keyToAsk = `${activeTabItem}_${activeSubItemInMap?.entityName}`;
        if (
          keyToAsk === row.key &&
          !_.isNil(row.formInstanceId) &&
          !_.isNil(formInstanceId) &&
          formInstanceId === row.formInstanceId &&
          !_.isNil(row.data)
        ) {
          // console.log({
          //   a: "recover form state from global state",
          //   fid: row.formInstanceId,
          //   data: row.data,
          // });
          setFormData(row.data);
          setFormInstanceId(row.formInstanceId);
          found = true;
          break;
        }
      }

      if (!found && !_.isNil(formStatus)) {
        if (!initialFormState) {
          setInitialformState(formStatus);
        }
        //tariq
        if (
          activeSubItem &&
          activeSubItem.has(activeTabItem) &&
          activeSubItem.get(activeTabItem)?.entityName &&
          !_.some(activeSubItemObject, { formInstanceId }) &&
          !_.isNil(formInstanceId)
        ) {
          // console.log({
          //   a: "added state NOT FOUND",
          //   fid: formInstanceId,
          //   data: formStatus,
          // });
          const activeSubItemInMap = activeSubItem.get(activeTabItem);
          const keyToSet = `${activeTabItem}_${activeSubItemInMap?.entityName}`;
          saveActiveSubItem({
            key: keyToSet,
            data: formStatus,
            isFirst: "first",
            formInstanceId,
          });

          setFormData(formStatus);
          setInitialFormData(formStatus);
          const formInstanceIdToSet = formInstanceId;
          setFormInstanceId(formInstanceIdToSet);
        }
      }
    } else {
      if (
        !_.isNil(formStatus) &&
        !_.isEmpty(formStatus) &&
        !_.isNil(formInstanceId)
      ) {
        if (!initialFormState) {
          setInitialformState(formStatus);
        }
        //tariq
        if (
          activeSubItem &&
          activeSubItem.has(activeTabItem) &&
          activeSubItem.get(activeTabItem)?.entityName
        ) {
          // console.log({
          //   a: "added state NEW",
          //   fid: formInstanceId,
          //   data: formStatus,
          // });
          const activeSubItemInMap = activeSubItem.get(activeTabItem);
          const keyToSet = `${activeTabItem}_${activeSubItemInMap?.entityName}`;
          saveActiveSubItem({
            key: keyToSet,
            data: formStatus,
            isFirst: "first",
            formInstanceId,
          });
        }
        setFormData(formStatus);
        setInitialFormData(formStatus);
        const formInstanceIdToSet = formInstanceId;
        setFormInstanceId(formInstanceIdToSet);
      }
    }
    // eslint-disable-next-line
  }, [activeSubItem, formStatus, formInstanceId]);

  //Push back to Records Panel
  const pushBackToList = async (success, formInstanceIdParam) => {
    if (
      activeTabItem &&
      activeSubItem &&
      activeSubItem.has(activeTabItem) &&
      !_.isNil(activeSubItemObject) &&
      !_.isEmpty(activeSubItemObject)
    ) {
      const activeSubItemInMap = activeSubItem.get(activeTabItem);
      if (!_.isNil(activeSubItemInMap)) {
        removeActiveSubItemObjectByFid(formInstanceIdParam);
        clearActiveSubItem(activeSubItemInMap);
        notifyServerCleanFormInstanceId({
          formInstanceId: formInstanceId || formInstanceIdParam,
          requestHeaders: REQUEST_HEADERS,
        });
        await removeSubTabItem(activeSubItemInMap);
      }
    }

    if (!_.isNil(oldRoute)) {
      history.push(oldRoute, {
        idForm,
        path,
        prevRecordsPanelState: {
          ...prevRecordsPanelState,
          operation: actualOperation,
          success,
        },
      });
    } else {
      const routeToBack = pathname.replace(`/${operation}`, "");
      history.push(routeToBack, {
        idForm,
        path,
        prevRecordsPanelState: {
          ...prevRecordsPanelState,
          operation: actualOperation,
          success,
        },
      });
    }
  };

  const refreshInNew = (formInstanceIdParam) => {
    setFormStatus(removeActiveSubItemObjectByFid(formInstanceIdParam));
    setRouteId(uuid());
    if (featureCleanAfterNew === true && !_.isNil(location)) {
      const finalRouteToMove = `/state/cleaner/middleware/${_.replace(
        _.startsWith(pathname, "/") ? pathname.substring(1) : pathname,
        new RegExp("/", "g"),
        "@"
      )}`;
      history.push(finalRouteToMove, location.state);
    }
  };

  //Execute action by operation NEW, EDIT, DELETE
  const executeAction = async () => {
    isFetching(true);
    const method = getHTTPMethodByOperaton(actualOperation);

    const audit = {
      ...deviceInfo,
      userName: auth?.userName,

      clientDateMod: format(new Date(), Constants.DATE_TIME_PATTERN),
      operation: actualOperation,
      entityName,
      tableName,
      solution,
    };

    const result = await confirmForm(
      { formInstanceId, audit },
      REQUEST_HEADERS
    );

    let toastContent = null;

    if (result) {
      const { status, ok, msg, token } = result;

      if (status === Constants.HTTP_STATUS_UNAUTHORIZED) {
        logout(true);
      } else if (status === Constants.HTTP_STATUS_NOT_FOUND) {
        toastContent = {
          title: t("ERROR_RESOURCE_NOT_FOUND_TEXT"),
        };
      } else {
        if (ok) {
          const toastTextSucces = t("FORM_SUCCESS_ACTION");
          //front_after_persist
          if (formConfig && formConfig.hasFrontAfterPersistServices) {
            const afterPersistResolution =
              await handleAfterPersistServicesExecution();
            if (afterPersistResolution) {
              toastId.current = toast.success(toastTextSucces, {
                containerId: Constants.TOAST_CONTAINER_FORM_CONTAINER,
                position: "top-right",
                autoClose: 1500,
              });
            }
          } else {
            toastId.current = toast.success(toastTextSucces, {
              containerId: Constants.TOAST_CONTAINER_FORM_CONTAINER,
              position: "top-right",
              autoClose: 1500,
            });
          }

          renewToken(token);

          if (
            loopNewEnabled &&
            actualOperation === Constants.FORM_OPERATION_NEW
          ) {
            setTimeout(() => {
              refreshInNew(formInstanceId);
            }, 1700);
          } else {
            setTimeout(() => {
              pushBackToList(true, formInstanceId);
            }, 1700);
          }
        } else {
          if (msg && msg !== "") {
            toastContent = {
              title: msg,
            };
          } else {
            toastContent = {
              title: t("FORM_ERROR_ACTION"),
            };
          }
        }
      }
    } else {
      toastContent = {
        title: t("ERROR_RESOURCE_NOT_FOUND_TEXT"),
      };
    }

    if (toastContent !== null) {
      renderBlockingToast({
        type: Constants.TOAST_TYPE_ERROR,
        ...toastContent,
      });
    }

    isFetching(false);
    if (!result && method === Constants.METHOD_PUT) isAlreadyDone(false);
  };

  //In charge of notify fields value to server ONLY SIMPLE FIELDS (grid fields are managed by GridContainer)
  const notifySetValueToServer = async (
    formInstanceId,
    completeIdControl,
    fieldValue,
    line,
    controlRef
  ) => {
    const valueHasChangeB = valueHasChanged(shadowStatus, {
      field: completeIdControl,
      newValue: fieldValue,
      line,
    });

    if (valueHasChangeB) {
      setIsSettingValueInServer(true);

      //Await for server response
      const response = await callServerToSetValue(
        formInstanceId,
        completeIdControl,
        fieldValue,
        line,
        REQUEST_HEADERS
      );
      //Make toast content
      let toastContent = null;

      //Process response
      if (response && response.status === Constants.HTTP_STATUS_UNAUTHORIZED) {
        logout(true);
      } else if (
        response &&
        response.status === Constants.HTTP_STATUS_NOT_FOUND
      ) {
        toastContent = {
          title: `${t("ERROR_RESOURCE_NOT_FOUND_TEXT")}`,
        };
      } else if (isServerResponseValid(response)) {
        transformStatusAndSetInState(response, completeIdControl);
      } else if (
        !_.isNil(response) &&
        !_.isNil(response.error) &&
        response.error === true &&
        !_.isNil(response.msg)
      ) {
        transformStatusAndSetInState(response, null);

        //Extract error message if there is one
        const errorText = extractErrorMessageFromResponse(response);

        //Make new error map adding this one for the control
        const newErroMap = makeErrorMapToSet(
          errorMap,
          completeIdControl,
          errorText
        );

        //Set new error map with the control ones updated with this error
        syncErrorMap(newErroMap);

        toastContent = {
          title: errorText,
        };

        returnFocusToRef(controlRef);
      }

      //Throw notification
      if (toastContent !== null) {
        renderBlockingToast({
          type: Constants.TOAST_TYPE_ERROR,
          ...toastContent,
        });
      }

      setIsSettingValueInServer(false);
    }
  };

  function transformStatusAndSetInState(response, completeIdControl) {
    //Destr fstatus
    const { fstatus } = response;
    const transformedFormStatus = transformFormStatusFromServer(fstatus);
    const newGlobalData = { ...formData, ...transformedFormStatus };

    if (completeIdControl) {
      //Check if control has errors to clear them
      checkToClearErrorMsg(completeIdControl);
    }

    //Sync state
    handleFormDataChange(newGlobalData);
    updateShadowStatus(fstatus);
  }

  // 
  const handleFormDataChange = async (formData) => {
    // Actualizar el estado local primero
    await new Promise(resolve => {
      setFormData(formData);
      setTimeout(resolve, 0);
    });

    // Manejar activeSubItem si es necesario
    if (
      activeSubItem &&
      activeSubItem.has(activeTabItem) &&
      activeSubItem.get(activeTabItem)?.entityName
    ) {
      const activeSubItemInMap = activeSubItem.get(activeTabItem);
      const keyToSet = `${activeTabItem}_${activeSubItemInMap?.entityName}`;
      saveActiveSubItem({
        key: keyToSet,
        data: formData,
        isFirst: "updated",
        formInstanceId,
      });
    }

    return formData;
  };

  //Sync error map between inner components and fom container(here)
  const syncErrorMap = (arrayOfErrorsToSync) => {
    if (!_.isNil(arrayOfErrorsToSync)) {
      setErrorMap(arrayOfErrorsToSync);
    }
  };

  //Check in error map if some error is assosciated with completeIdControl
  //If it has, remove toast and sync map
  const checkToClearErrorMsg = (completeIdControl) => {
    const controlWithError = controlHasError(errorMap, completeIdControl);
    if (controlWithError) {
      let newMap = _.clone(errorMap);
      newMap = newMap.filter(function (obj) {
        return obj.key !== completeIdControl;
      });
      //Set new error map without control ones
      setErrorMap(newMap);
      //Dismiss possible notifications of this control
      toast.dismiss(completeIdControl);
    }
  };

  useEffect(() => {
    if (!_.isNil(formSchema) && !_.isNil(formSchema.sections)) {
      const sortedtoAdd = formSchema.sections.sort(
        (a, b) => a.position - b.position
      );

      setSortedSections(sortedtoAdd);
      const textHeaderMade =
        !_.isNil(formSchema?.name) && formSchema?.name !== ""
          ? formSchema?.name + " (" + getOperationText() + ")"
          : "";

      changeTextHeader(textHeaderMade);
    }

    return () => null;
    // eslint-disable-next-line
  }, [formSchema]);

  //let errorListToShow = getErrorList(errorMap);

  const handleExecuteAction = async () => {
    if (actualOperation === Constants.FORM_OPERATION_DELETE) {
      setDeleteFormConfirmation(true);
    } else {
      if (formConfig && formConfig.hasFrontConfirmServices) {
        const allExecuted = await handleClientServicesExecution();

        if (allExecuted) {
          executeAction();
        }
      } else {
        executeAction();
      }
    }
  };

  async function handleClientServicesExecution() {
    let toastContent = null;
    let resolution = false;

    isFetching(true);
    const response = await formAPI.getFrontConfirmServices(
      formInstanceId,
      auth.token
    );

    if (response) {
      const { error, msgError, services } = response;

      if (!error) {
        if (services && services.length > 0) {
          for (let i = 0; i < services.length; i++) {
            const service = services[i];

            if (service && service.serviceName && service.toReturn) {
              const {
                serviceName,
                params,
                errorMessage,
                dataResultDef,
                toReturn,
                key,
              } = service;
              const serviceInstance = clientServices[serviceName];

              if (serviceInstance) {
                const serviceBody = {
                  serviceName,
                  requestData: {
                    params,
                    dataResultDef,
                    company: auth?.company?.codCompany,
                  },
                  formInstanceId,
                  key,
                  line: 0,
                };

                const serviceResult = await serviceInstance.execute(
                  {
                    serviceBody,
                    REQUEST_HEADERS,
                  },
                  errorMessage,
                  serviceName,
                  formInstanceId
                );

                if (
                  serviceResult &&
                  serviceResult.length > 0 &&
                  toReturn &&
                  toReturn.length > 0
                ) {
                  for (let r = 0; r < toReturn.length; r++) {
                    const whichValue = toReturn[r]?.from;
                    const controlToSet = toReturn[r]?.to;
                    const valueToSet = serviceResult[whichValue];

                    await notifySetValueToServer(
                      formInstanceId,
                      controlToSet,
                      valueToSet,
                      0,
                      null
                    );
                  }
                }

                if (
                  !_.isNil(serviceResult) &&
                  !_.isNil(serviceResult.toastContent) &&
                  _.isString(serviceResult.toastContent)
                ) {
                  toastContent = (
                    <CustomBodyToast
                      msg={null}
                      title={serviceResult.toastContent}
                    />
                  );
                  resolution = false;
                } else {
                  resolution = true;
                }
              }
            }
          }
        }
      } else {
        toastContent = <CustomBodyToast msg={null} title={msgError} />;
        resolution = false;
      }
    }

    if (toastContent !== null) {
      toast.error(toastContent, {
        containerId: Constants.TOAST_CONTAINER_FORM_CONTAINER,
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    isFetching(false);

    return resolution;
  }

  async function handleAfterPersistServicesExecution() {
    let toastContent = null;
    let resolution = false;

    isFetching(true);
    const response = await formAPI.getFrontAfterPersistServices(
      formInstanceId,
      auth.token
    );

    if (response) {
      const { error, msgError, services } = response;

      if (!error) {
        if (services && services.length > 0) {
          for (let i = 0; i < services.length; i++) {
            const service = services[i];

            if (service && service.serviceName && service.toReturn) {
              const { serviceName, params, errorMessage, dataResultDef, key } =
                service;
              const serviceInstance = clientServices[serviceName];

              if (serviceInstance) {
                const hastToSubstituteValuesInParams =
                  _.has(serviceInstance, "hasToSubstituteValuesInParams") &&
                  _.isFunction(serviceInstance.hasToSubstituteValuesInParams) &&
                  serviceInstance.hasToSubstituteValuesInParams() === true;

                const paramsWithValues = hastToSubstituteValuesInParams
                  ? resolveValuesFieldAgainsShadowStatus({
                    values: params,
                    shadowStatus: shadowStatus,
                    completeIdControl: null,
                  })
                  : params;

                const serviceBody = {
                  serviceName,
                  requestData: {
                    params: paramsWithValues,
                    dataResultDef,
                    company: auth?.company?.codCompany,
                  },
                  formInstanceId,
                  key,
                  line: 0,
                };

                const serviceResult = await serviceInstance.execute(
                  {
                    serviceBody,
                    REQUEST_HEADERS,
                  },
                  errorMessage,
                  serviceName,
                  formInstanceId
                );

                if (
                  !_.isNil(serviceResult) &&
                  !_.isNil(serviceResult.toastContent) &&
                  _.isString(serviceResult.toastContent)
                ) {
                  toastContent = (
                    <CustomBodyToast
                      msg={null}
                      title={serviceResult.toastContent}
                    />
                  );
                  resolution = false;
                } else {
                  resolution = true;
                }
              }
            }
          }
        }
      } else {
        toastContent = <CustomBodyToast msg={null} title={msgError} />;
        resolution = false;
      }
    }

    if (toastContent !== null) {
      toast.warning(toastContent, {
        containerId: Constants.TOAST_CONTAINER_FORM_CONTAINER,
        position: "top-right",
        autoClose: 1500,
      });
    }

    isFetching(false);

    return resolution;
  }

  async function changeOperation(newOperation) {
    if (actualOperation === newOperation) {
      return;
    } else {
      const response = await notifyServerChangeOperation(
        {
          formInstanceId,
          operation: newOperation,
        },
        REQUEST_HEADERS
      );
      const { ok, msg } = response;

      if (ok) {
        setActualOperation(newOperation);
        updateFormOperationInStore(formInstanceId, newOperation);
      } else {
        //Throw notification
        toast.error(msg, {
          containerId: Constants.TOAST_CONTAINER_FORM_CONTAINER,
          toastId: "changeOperationError",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  function handleTSDialog(open) {
    setOpenTSDialog(open);
  }

  useEffect(() => {
    const handler = (e) => {
      const { keyCode, altKey } = e;

      if (altKey && keyCode === KEY_T) {
        e.preventDefault();
        setShowTrazability((show) => !show);
      }
    };

    window.addEventListener("keydown", handler);

    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, []);

  const toggleIsSettingValueToServer = useCallback((toggle) => {
    setIsSettingValueInServer(toggle);
  }, []);

  // function getRowHeight(containerHeight) {
  //   const calculated =
  //     (Number.parseInt(containerHeight) - layoutCols * 10) / 12;
  //   if (calculated && calculated > 0)
  //     console.log(`calculatedRowHeight: ${calculated}px `);
  //   return calculated;
  // }

  function handleChangeBreakpoint(newBreakpoint) {
    setSelectedBreak(newBreakpoint);
  }

  const jasperReports = useMemo(() => {
    if (!_.isNil(formSchema) && !_.isNil(formSchema.jasper)) {
      return formSchema.jasper;
    } else {
      return [];
    }
  }, [formSchema]);

  async function handleJasperReport(jasperParams) {
    setGeneratingJasper(true);
    let toastContent = null;
    const response = await callExportJasper(
      {
        path,
        idForm,
        formInstanceId,
        ...jasperParams,
      },
      REQUEST_HEADERS
    );

    if (response) {
      const { ok, errorMsg, dataResponse, status } = response;
      if (status === Constants.HTTP_STATUS_UNAUTHORIZED) {
        logout(true);
      } else if (ok && dataResponse) {
        const { url, reportName } = dataResponse;
        if (url && reportName) {
          saveAs(url, reportName);
        }
      } else if (ok && errorMsg) {
        toast.info(errorMsg, {
          containerId: Constants.TOAST_CONTAINER_FORM_CONTAINER,
          position: "top-right",
          autoClose: 5000,
        });
      } else {
        toastContent = (
          <CustomBodyToast
            msg={null}
            title={errorMsg || t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
          />
        );
      }
    } else {
      toastContent = (
        <CustomBodyToast
          msg={null}
          title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
        />
      );
    }

    if (toastContent !== null) {
      toast.error(toastContent, {
        containerId: Constants.TOAST_CONTAINER_FORM_CONTAINER,
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    handleCloseJasperOptions();
  }

  const handleJasperOption = (event) => {
    setAnchorElJasperOpt(event.currentTarget);
  };

  const handleCloseJasperOptions = () => {
    setAnchorElJasperOpt(null);
    setGeneratingJasper(false);
  };

  const showCloseFormDialogBase = useMemo(() => {
    return (
      !_.isNil(formData) &&
      !_.isNil(formData.formChanged) &&
      formData.formChanged === true &&
      (actualOperation === Constants.FORM_OPERATON_EDIT ||
        actualOperation === Constants.FORM_OPERATION_NEW)
    );
  }, [formData, actualOperation]);

  function handleCancelAndBackClicks() {
    if (showCloseFormDialogBase) {
      setExecutedAction(true);
    } else {
      pushBackToList();
    }
  }

  function handleCloseSubTab(leave) {
    setExecutedAction(false);
    if (leave) {
      pushBackToList();
    }
  }

  const getValueInShadowStatus = useCallback(
    (field, completeIdControl) => {
      return resolveFieldAgainstShadowStatus({
        field,
        shadowStatus,
        completeIdControl,
      });
    },
    [shadowStatus]
  );

  const resolveTitleBlockWithFormStatus = useCallback(
    (blockTitle) => {
      if (
        !_.isNil(blockTitle) &&
        _.isString(blockTitle) &&
        _.trim(blockTitle) !== "" &&
        (_.startsWith(blockTitle, "#") || _.startsWith(blockTitle, "$"))
      ) {
        const valueInShadowStatus = getValueInShadowStatus(
          blockTitle,
          blockTitle
        );
        return !_.isNil(valueInShadowStatus) ? valueInShadowStatus : blockTitle;
      } else {
        return blockTitle;
      }
    },
    [getValueInShadowStatus]
  );

  const filteredGridLayoutItemsByVisibility = useMemo(() => {
    const blocks = formSchema?.sections?.[selectedTab]?.blocks;

    if (
      _.isNil(gridLayout) ||
      _.isEmpty(gridLayout) ||
      _.isNil(blocks) ||
      _.isEmpty(blocks) ||
      _.isNil(shadowStatus)
    ) {
      return [gridLayout, formSchema?.sections?.[selectedTab]?.blocks];
    }

    // Crear un objeto con los ids de los bloques visibles
    const visibleBlocks = _.filter(blocks, function (o) {
      return _.isBoolean(o?.isVisible)
        ? o?.isVisible
        : _.isString(o?.isVisible)
        ? _.toLower(getValueInShadowStatus(o?.isVisible, o?.isVisible)) === "s"
        : true;
    }).map((block) => block?.id);

    // Filtrar el gridLayout para cada breakpoint, asegurándose de que los accesos sean seguros
    const filteredGridLayout = Object.keys(gridLayout).reduce(
      (acc, breakpoint) => {
        const layout = _.get(gridLayout, breakpoint, []);
        acc[breakpoint] = layout.filter((item) =>
          visibleBlocks.includes(item.i)
        );
        return acc;
      },
      {}
    );

    return [filteredGridLayout, visibleBlocks];
  }, [
    formSchema,
    getValueInShadowStatus,
    gridLayout,
    selectedTab,
    shadowStatus,
  ]);

  return (
    <>
      <CloseSubTabDialog
        open={showCloseFormDialogBase && executedAction}
        handleAction={handleCloseSubTab}
      />
      {/* <Prompt
        when={
          !_.isNil(formData) &&
          !_.isNil(formData.formChanged) &&
          formData.formChanged === true &&
          (actualOperation === Constants.FORM_OPERATON_EDIT ||
            actualOperation === Constants.FORM_OPERATION_NEW) &&
          executedAction === false
        }
        message={(location) => t("FORM_LEAVE_CHANGE_ROUTE_CONFIRMATION")}
      /> */}
      <BlockingToastModal />
      <GenericToastContainer
        containerId={Constants.TOAST_CONTAINER_FORM_CONTAINER}
      />
      {formInstanceId && openTSDialog && (
        <TrackingServicesDialog
          formInstanceId={formInstanceId}
          handleOpen={handleTSDialog}
          open={openTSDialog}
          requestHeaders={REQUEST_HEADERS}
        />
      )}

      {/* SPINNER WHEN IT IS FECTHING */}
      {loading === true ? (
        <div className={classes.fetchingSpinnerContainer}>
          <CircularProgress className={classes.fetchingSpinner} />
        </div>
      ) : loading === false && _.isNil(formSchema) ? (
        <NothingToShow />
      ) : (
        // MAIN CONTAINER
        <div className={classes.mainContainer}>
          {/* HEADER FORM TITLE + BUTTON OPERATIONS */}
          <div className={classes.formHeader}>
            {/* START BUTTON GROUP TO CHANGE OPERATION FROM VIEW TO EDIT or  DELETE*/}
            {actualOperation !== Constants.FORM_OPERATION_NEW ? (
              <div className={classes.footerStartButtonGroup}>
                {/*CHANGE TO VIEW BUTTON */}
                {!_.isNil(formOperations) &&
                  !_.isEmpty(formOperations) &&
                  formOperations.indexOf(Constants.FORM_OPERATION_VIEW) >
                  -1 && (
                    <Tooltip title={t("TABLE_PANEL_DECISION_VIEW")}>
                      <button
                        className={
                          !_.isNil(actualOperation) &&
                            actualOperation === Constants.FORM_OPERATION_VIEW
                            ? classes.editButtonCommonSelected
                            : classes.editButtonCommon
                        }
                        style={{ marginLeft: 0 }}
                        onClick={() =>
                          changeOperation(Constants.FORM_OPERATION_VIEW)
                        }
                        id={"form_container_change_vie_button"}
                      >
                        <Icon
                          className="fas fa-eye"
                          style={{
                            fontSize: "1.2em",
                            color:
                              !_.isNil(actualOperation) &&
                                actualOperation === Constants.FORM_OPERATION_VIEW
                                ? "white"
                                : theme.palette.content.mainColor,
                            width: "auto",
                          }}
                        />
                      </button>
                    </Tooltip>
                  )}
                {/* JASPER REPORTS EXPORTS */}
                {actualOperation === Constants.FORM_OPERATION_VIEW &&
                  jasperReports.length > 0 && (
                    <div>
                      <Tooltip title={t("JASPER_BUTTON_LABEL")}>
                        <button
                          className={classes.editButtonCommon}
                          style={{ marginLeft: 0 }}
                          onClick={(e) => {
                            if (jasperReports.length === 1) {
                              handleJasperReport(jasperReports[0]);
                            } else {
                              handleJasperOption(e);
                            }
                          }}
                          id={"form_container_jasper_button"}
                        >
                          {generatingJasper ? (
                            <CircularProgress size={20} />
                          ) : (
                            <Icon
                              className="fas fa-print"
                              style={{
                                fontSize: "1.2em",
                                color: theme.palette.content.mainColor,
                                width: "auto",
                              }}
                            />
                          )}
                        </button>
                      </Tooltip>
                      {jasperReports.length > 1 && !generatingJasper && (
                        <Menu
                          id="jasper-reports-options"
                          anchorEl={anchorElJasperOpt}
                          keepMounted
                          open={Boolean(anchorElJasperOpt)}
                          onClose={handleCloseJasperOptions}
                        >
                          {jasperReports.map((jasper, index) => (
                            <MenuItem
                              key={`${jasper?.resultReportName}_${index}`}
                              onClick={() => handleJasperReport(jasper)}
                            >
                              {jasper?.resultReportName}
                            </MenuItem>
                          ))}
                        </Menu>
                      )}
                    </div>
                  )}
                {/* CHANGE TO EDIT BUTTON */}
                {!_.isNil(formOperations) &&
                  !_.isEmpty(formOperations) &&
                  formOperations.indexOf(Constants.FORM_OPERATON_EDIT) > -1 && (
                    <Tooltip title={t("TABLE_PANEL_DECISION_EDIT")}>
                      <button
                        className={
                          !_.isNil(actualOperation) &&
                            actualOperation === Constants.FORM_OPERATON_EDIT
                            ? classes.editButtonCommonSelected
                            : classes.editButtonCommon
                        }
                        onClick={() =>
                          changeOperation(Constants.FORM_OPERATON_EDIT)
                        }
                        id={"form_container_change_upd_button"}
                      >
                        <Icon
                          className="fas fa-edit"
                          style={{
                            fontSize: "1.2em",
                            color:
                              !_.isNil(actualOperation) &&
                                actualOperation === Constants.FORM_OPERATON_EDIT
                                ? "white"
                                : theme.palette.content.mainColor,
                            width: "auto",
                          }}
                        />
                      </button>
                    </Tooltip>
                  )}
                {/* CHANGE TO  DELETE BUTTON  */}
                {!_.isNil(formOperations) &&
                  !_.isEmpty(formOperations) &&
                  formOperations.indexOf(Constants.FORM_OPERATION_DELETE) >
                  -1 && (
                    <Tooltip title={t("TABLE_PANEL_DECISION_DELETE")}>
                      <button
                        className={
                          !_.isNil(actualOperation) &&
                            actualOperation === Constants.FORM_OPERATION_DELETE
                            ? classes.deleteButtonCommonSelected
                            : classes.deleteButtonCommon
                        }
                        onClick={() =>
                          changeOperation(Constants.FORM_OPERATION_DELETE)
                        }
                        id={"form_container_change_del_button"}
                      >
                        <Icon
                          className="fas fa-trash-alt"
                          style={{
                            fontSize: "1.2em",
                            color:
                              !_.isNil(actualOperation) &&
                                actualOperation ===
                                Constants.FORM_OPERATION_DELETE
                                ? "white"
                                : "#f87979",
                            width: "auto",
                          }}
                        />
                      </button>
                    </Tooltip>
                  )}
              </div>
            ) : (
              <div> </div>
            )}

            {formSchema && formSchema.name ? (
              <div className={classes.formNameDiv}>
                <Icon
                  className="fas fa-table"
                  style={{ ...commonIcon, marginRight: 5 }}
                />
                {Constants.IS_FORM_BUILDING_HELP_ENABLED &&
                  !_.isNil(formInstanceId) ? (
                  <Typography variant="h5" style={{ marginLeft: 5 }}>
                    {`${formSchema.name} #${formInstanceId.slice(-4)}`}
                  </Typography>
                ) : (
                  <Typography variant="h5">{formSchema.name}</Typography>
                )}
              </div>
            ) : (
              <div />
            )}
            <div />
            <Box display="flex" alignItems="center" justifyContent="center">
              <HelpDialog
                hasHelp={formSchema?.hasHelp}
                path={path}
                idForm={idForm}
                nameForm={formSchema?.name}
              />
              <BreakpointChooser
                actualBreak={selectedBreak}
                handleChangeBreakpoint={handleChangeBreakpoint}
                definedLayouts={gridLayout}
              />
            </Box>
          </div>

          {/* CONTENT CONTAINER (TABS AND FORM)*/}
          <div className={classes.contentContainerColumn}>
            {/* TABS CONTAINER (LEFT SIDE,  VERTICAL MODE) */}
            <div className={classes.tabsContainer}>
              {!_.isNil(formSchema) &&
                !_.isNil(formSchema.sections) &&
                !_.isNil(sortedSections) &&
                sortedSections.length > 0 && (
                  <Tabs
                    TabIndicatorProps={{
                      className: classes.tabIndicatorProps,
                    }}
                    orientation="horizontal"
                    value={selectedTab}
                    onChange={(event, newTab) => {
                      handleTabChange(event, newTab);
                      removeBadge({ formInstanceId });
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="form-tabs"
                  >
                    {sortedSections.map((section) => {
                      return (
                        <Tab
                          key={section?.name}
                          label={
                            <BadgeWizardStepSection
                              shouldRenderBadge={shouldRenderBadge({
                                formInstanceId,
                                sectionName: section?.name,
                              })}
                            >
                              {Constants.IS_FORM_BUILDING_HELP_ENABLED
                                ? `${section?.label} (${section.name})`
                                : section?.label}
                            </BadgeWizardStepSection>
                          }
                          id={`form_container_tab_${section?.name}`}
                          classes={{
                            root: classes.tabRoot,
                            wrapper: classes.tabIconWrapper,
                            labelIcon: classes.tabLabelIcon,
                          }}
                          icon={
                            !_.isNil(section?.sectionIcon) ? (
                              <Icon
                                className={section?.sectionIcon}
                                style={{ marginBottom: 0 }}
                              />
                            ) : null
                          }
                        />
                      );
                    })}
                  </Tabs>
                )}
            </div>

            {/* FORM CONTAINER */}
            {!_.isNil(formSchema) && !_.isNil(formSchema.sections) && (
              <div
                ref={formContentContainerRef}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <SizeMe on monitorHeight refreshRate={200}>
                  {({ size }) => {
                    const parentHeight =
                      formContentContainerRef?.current?.clientHeight || null;
                    const calculatedHeight = Number.parseInt(size?.height);
                    const heightToApply =
                      !_.isNil(calculatedHeight) &&
                        _.isNumber(calculatedHeight) &&
                        !_.isNaN(calculatedHeight) &&
                        calculatedHeight > 0
                        ? calculatedHeight
                        : "100%";

                    const maxHeightToApplySubstract =
                      actualOperation !== Constants.FORM_OPERATION_EXECUTION
                        ? 265
                        : 220;

                    return (
                      <div
                        style={{
                          width: "100%",
                          height: parentHeight || heightToApply,
                          maxHeight: `calc( 100vh - ${maxHeightToApplySubstract}px)`,
                        }}
                      >
                        <ResponsiveGridLayout
                          key={selectedTab}
                          breakpoints={{
                            lg: Constants.BREAKPOINT_LG,
                            md: Constants.BREAKPOINT_MD,
                            sm: Constants.BREAKPOINT_SM,
                            xs: 0,
                          }}
                          cols={{ lg: 12, md: 10, sm: 4, xs: 1 }}
                          containerPadding={[5, 5]}
                          isDraggable={false}
                          isResizable={false}
                          layouts={filteredGridLayoutItemsByVisibility[0]}
                          margin={[5, 5]}
                          rowHeight={34}
                          style={{
                            padding: 0,
                            height: heightToApply,
                            overflowY: "auto",
                            position: "relative",
                            overflowX: "hidden",
                          }}
                          width={selectedBreak || Number.parseInt(size?.width)}
                          onBreakpointChange={(nb) => {
                            changeLayoutBreakpoint(nb);
                          }}
                        >
                          {_.filter(
                            formSchema?.sections?.[selectedTab]?.blocks,
                            function (o) {
                              return _.includes(
                                filteredGridLayoutItemsByVisibility[1],
                                o?.id
                              );
                            }
                          ).map((block, index) => {
                            const {
                              id,
                              classStyles,
                              schema,
                              uiSchema,
                              blockType,
                              columns,
                              listName,
                              enableAddRow,
                              enableDeleteRow,
                              pre,
                              colDisableDeleteRow,
                              showSearchBarRow,
                              maxRows,
                              //isVisible,
                              denyAddRow,
                              disableMassiveGridLoad,
                              hideRadioButtonsGrid,
                            } = block;

                            const hasLegend =
                              !_.isNil(schema?.title) &&
                              !_.isEmpty(schema?.title);

                            // const isVisibleToUse = _.isBoolean(isVisible)
                            //   ? isVisible
                            //   : _.isString(isVisible)
                            //   ? _.toLower(
                            //       getValueInShadowStatus(isVisible, isVisible)
                            //     ) === "s"
                            //   : true;

                            const wizardConfig = formSchema?.wizard;

                            const isBlockDisabledByWizard = !_.isNil(
                              wizardConfig
                            )
                              ? _.includes(arrayBlocksDisabledByWizard, id)
                              : false;

                            return (
                              <div key={id} className={classStyles}>
                                <BlockUi
                                  tag="div"
                                  blocking={isSettingValueInServer}
                                  style={{
                                    width: "100%",
                                    height: hasLegend
                                      ? "100%"
                                      : "calc(100% - 1.7px)",
                                  }}
                                >
                                  {blockType === Constants.BLOCK_TYPE_SIMPLE ||
                                  _.isNil(blockType) ? (
                                    <SimpleBlock
                                      {...block}
                                      pre={pre}
                                      hasLegend={hasLegend}
                                      shadowStatus={shadowStatus}
                                      wizardConfig={wizardConfig}
                                      formSchema={formSchema}
                                      selectedTab={selectedTab}
                                      moveToAnotherSectionByIndex={
                                        moveToAnotherSectionByIndex
                                      }
                                      formInstanceId={formInstanceId}
                                      isListBlock={false}
                                      operation={actualOperation}
                                    >
                                      <Form
                                        //idPrefix={`${id}_${index}`}
                                        key={`${id}_${index}`}
                                        children={true}
                                        schema={
                                          Constants.IS_FORM_BUILDING_HELP_ENABLED
                                            ? {
                                                ...schema,
                                                title: `${resolveTitleBlockWithFormStatus(
                                                  schema.title
                                                )} (${id})`,
                                              }
                                            : {
                                                ...schema,
                                                title: `${resolveTitleBlockWithFormStatus(
                                                  schema.title
                                                )}`,
                                              }
                                        }
                                        formData={formData}
                                        uiSchema={
                                          !_.isNil(uiSchema) ? uiSchema : {}
                                        }
                                        onChange={(e) => {
                                          //tariq
                                          //handleFormDataChange(e.formData)
                                        }}
                                        onSubmit={(e) => {
                                          if (
                                            !_.isNil(e) &&
                                            _.has(e, "preventDefault") &&
                                            _.isFunction(e.preventDefault)
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                        formContext={{
                                          globalFormData: formData,
                                          syncErrorMap,
                                          errorMap,
                                          operation: !_.isNil(actualOperation)
                                            ? actualOperation
                                            : operation,
                                          initialFormData,
                                          section:
                                            formSchema.sections[selectedTab],
                                          block: id,
                                          formInstanceId,
                                          handleFormDataChange,
                                          notifySetValueToServer,
                                          formWithErrors:
                                            errorMap && errorMap.length > 0,
                                          isSettingValueInServer,
                                          pre,
                                          checkToClearErrorMsg,
                                          shadowStatus,
                                          transformStatusAndSetInState,
                                          showSearchBarRow,
                                          updateShadowStatus,
                                          wizardConfig,
                                          isBlockDisabledByWizard,
                                        }}
                                        fields={customSimpleFields}
                                        id={makeUniqueFormID(id)}
                                        registry={{
                                          fields: customSimpleFields,
                                        }}
                                        autoComplete="false"
                                        liveValidate={false}
                                        noHtml5Validate={true}
                                        noValidate={true}
                                      />
                                    </SimpleBlock>
                                  ) : blockType ===
                                    Constants.BLOCK_TYPE_LIST ? (
                                    <SimpleBlock
                                      {...block}
                                      pre={pre}
                                      key={`${id}_${schema?.title}_block`}
                                      hasLegend={
                                        !_.isNil(schema?.title) &&
                                        !_.isEmpty(schema?.title)
                                      }
                                      shadowStatus={shadowStatus}
                                      wizardConfig={wizardConfig}
                                      formSchema={formSchema}
                                      selectedTab={selectedTab}
                                      moveToAnotherSectionByIndex={
                                        moveToAnotherSectionByIndex
                                      }
                                      formInstanceId={formInstanceId}
                                      isListBlock={true}
                                      operation={actualOperation}
                                    >
                                      <form
                                        key={`${id}_${schema?.title}`}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          overflowX: "auto",
                                        }}
                                        onSubmit={(e) => e.preventDefault()}
                                      >
                                        <fieldset id={id}>
                                          {Constants.IS_FORM_BUILDING_HELP_ENABLED ? (
                                            <legend>{`${resolveTitleBlockWithFormStatus(
                                              schema?.title
                                            )} (${id}.${listName})`}</legend>
                                          ) : (
                                            <legend>
                                              {resolveTitleBlockWithFormStatus(
                                                schema?.title
                                              )}
                                            </legend>
                                          )}
                                          <GridTable
                                            key={`grid_table_${listName}`}
                                            columns={columns}
                                            title={""}
                                            theme={theme}
                                            schema={schema}
                                            formData={formData}
                                            dataInTable={
                                              !_.isNil(formData) &&
                                              !_.isNil(formData[listName])
                                                ? formData[listName]
                                                : []
                                            }
                                            handleFormDataChange={
                                              handleFormDataChange
                                            }
                                            name={listName}
                                            toastId={toastId}
                                            formContext={{
                                              formData,
                                              setFormData,
                                              syncErrorMap,
                                              errorMap,
                                              operation: !_.isNil(
                                                actualOperation
                                              )
                                                ? actualOperation
                                                : operation,
                                              initialFormData,
                                              section:
                                                formSchema.sections[
                                                  selectedTab
                                                ],
                                              block: id,
                                              formInstanceId,
                                              listName,
                                              enableAddRow,
                                              enableDeleteRow,
                                              formWithErrors:
                                                errorMap && errorMap.length > 0,
                                              toggleIsSettingValueToServer,
                                              shadowStatus,
                                              updateShadowStatus,
                                              colDisableDeleteRow,
                                              showSearchBarRow,
                                              maxRows,
                                              denyAddRow,
                                              disableMassiveGridLoad,
                                              hideRadioButtonsGrid,
                                              formSchema,
                                              isBlockDisabledByWizard,
                                            }}
                                          />
                                        </fieldset>
                                      </form>
                                    </SimpleBlock>
                                  ) : (
                                    <BadForm key={`${id}_${index}`} />
                                  )}
                                </BlockUi>
                              </div>
                            );
                          })}
                        </ResponsiveGridLayout>
                      </div>
                    );
                  }}

                  {/* </Measure> */}
                </SizeMe>
              </div>
            )}
          </div>

          {/* FOOTER DECISION BUTTON CONTAINER */}
          {actualOperation !== Constants.FORM_OPERATION_EXECUTION && (
            <div className={classes.footer}>
              {Constants.MULTI_ITEM_ENABLED && showSubItemsInForm ? (
                <Hidden smDown>
                  <SubTabsContainer />
                </Hidden>
              ) : (
                <div> </div>
              )}

              {formInstanceId && showTrazability && (
                <Tooltip title="Services and Graph Trazability">
                  <IconButton
                    aria-label="toggle tracking service dialog"
                    className={classes.iconButtonOpenTrazability}
                    id={"form_container_trazability_button"}
                    onClick={() => handleTSDialog(!openTSDialog)}
                  >
                    <Icon
                      className="fas fa-business-time"
                      style={{
                        ...commonIcon,
                        width: "auto",
                        height: "auto",
                        padding: 1,
                        fontSize: "1rem",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}

              {/* END BUTTON GROUP  (SAVE, CANCEL, BACK)*/}
              <div className={classes.footerEndButtonGroup}>
                {/* SAVE BUTTON WITH TOOLTIP AND BADGE FOR ERRORS */}
                {!_.isNil(formSchema) &&
                  actualOperation !== Constants.FORM_OPERATION_VIEW &&
                  actualOperation !== Constants.FORM_OPERATION_EXECUTION && (
                    <button
                      disabled={
                        fetching === true ||
                        alreadyDone === true ||
                        isSettingValueInServer === true
                        //errorMap.length > 0
                      }
                      className={classes.decisionButtonForm}
                      onClick={() => handleExecuteAction()}
                      id={ID_FORM_CONTAINER_ACTION_BUTTON}
                    >
                      <Icon
                        className={getFasIcon()}
                        style={{
                          ...commonIcon,
                          fontSize: "1.2em",
                          marginRight: "0.25em",
                          color: isSettingValueInServer
                            ? "grey"
                            : theme.palette.content.mainColor,
                        }}
                      />
                      {fetching === true ? (
                        <CircularProgress size={"1.2em"} />
                      ) : (
                        <strong className={classes.actionTypo}>
                          {getDescText()}
                        </strong>
                      )}
                    </button>
                  )}
                <DeleteFormConfirmDialog
                  confirm={(confirmed) => {
                    setDeleteFormConfirmation(false);
                    if (confirmed) {
                      executeAction();
                    }
                  }}
                  open={deleteFormConfirmation}
                />
                <Divider flexItem orientation="vertical" />
                {/* CANCEL BUTTON */}
                {!_.isNil(formSchema) &&
                  actualOperation !== Constants.FORM_OPERATION_VIEW &&
                  actualOperation !== Constants.FORM_OPERATION_EXECUTION && (
                    <button
                      className={classes.decisionButtonForm}
                      disabled={
                        fetching === true ||
                        alreadyDone === true ||
                        isSettingValueInServer === true
                      }
                      onClick={() => handleCancelAndBackClicks()}
                      id={"form_container_cancel_button"}
                    >
                      <Icon
                        className="fas fa-times"
                        style={{
                          ...commonIcon,
                          fontSize: "1.2em",
                          marginRight: "0.25em",
                          color: "red",
                        }}
                      />
                      <p className={classes.actionTypo}>{t("FORM_CANCEL")}</p>
                    </button>
                  )}
                <Divider flexItem orientation="vertical" />
                {/* BACK BUTTON WHEN IT IS EDITING TO AVOID CONFUSION WITH CANCEL BUTTON */}
                {actualOperation !== Constants.FORM_OPERATION_EXECUTION &&
                  Constants.MULTI_ITEM_ENABLED === false && (
                    <button
                      className={classes.decisionButtonForm}
                      disabled={
                        fetching === true ||
                        alreadyDone === true ||
                        isSettingValueInServer === true
                      }
                      onClick={() => handleCancelAndBackClicks()}
                      id={"form_container_back_button"}
                    >
                      <Icon
                        className="fas fa-arrow-circle-left"
                        style={{
                          ...commonIcon,
                          fontSize: "1.2em",
                          marginRight:
                            windowDimensions &&
                            windowDimensions.width &&
                            windowDimensions.width > Constants.BREAKPOINT_SM &&
                            "0.25em",
                          color: theme.palette.content.mainColorContrastText,
                        }}
                      />
                      {Boolean(
                        windowDimensions &&
                        windowDimensions.width &&
                        windowDimensions.width > Constants.BREAKPOINT_SM
                      ) && (
                          <p className={classes.actionTypo}>{t("FORM_BACK")}</p>
                        )}
                    </button>
                  )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
