import React, { useState, useEffect, lazy } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Icon,
  Paper,
  Typography,
  useTheme,
  Button,
  IconButton,
  Tab,
  Tabs,
} from "@material-ui/core";
import { toast } from "react-toastify";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

import CustomBodyToast from "../../toast/CustomBodyToast";
import { useStyles, commonIconStyles } from "./TrackingServicesDialogStyles";
import { TOAST_CONTAINER_LAYOUT } from "../../../util/Constants";
import { callServerToGetTrackingServicesList } from "../../../util/UtilForm";
import TabContainer from "./tabs/TabContainer";
import ServicesListTable from "./tabs/servicesList/ServicesListTable";

const JsonGraphVisualizer = lazy(() =>
  import("./tabs/graph/JsonGraphVisualizer")
);

/* COMPONENT TO MAKE DIALOG DRAGGABLE */
function DraggableComponent(props) {
  return (
    <Draggable
      handle="#tracking-services-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          height: "90%",
          width: "auto",
          minWidth: "90%",
        }}
      />
    </Draggable>
  );
}

function a11yProps(index) {
  return {
    id: `tracking_tabs-${index}`,
    "aria-controls": `tracking_tabs-${index}`,
  };
}

function TrackingServicesDialog({
  open,
  formInstanceId,
  handleOpen,
  requestHeaders,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const materialTheme = useTheme();
  const [fetching, setFetching] = useState(true);
  const [servicesList, setServicesList] = useState([]);
  const [jsonGraph, setJsonGraph] = useState({});

  const [tab, setTab] = useState(0);

  const handleTabChange = (event, newTab) => {
    setTab(newTab);
  };

  let totalDuration = servicesList.reduce(
    (sum, value) =>
      typeof value.demora == "number" ? sum + value.demora : sum,
    0
  );

  useEffect(() => {
    if (open) {
      getTrackingServicesList();
    }

    return () => null;
    //eslint-disable-next-line
  }, [open]);

  async function getTrackingServicesList() {
    if (fetching === false) setFetching(true);

    const urlParams = `formInstanceId=${formInstanceId}`;
    const response = await callServerToGetTrackingServicesList(
      urlParams,
      requestHeaders
    );

    let toastContent = null;
    if (response) {
      const { ok, errorMsg, dataResponse } = response;
      if (ok) {
        const { servicesList: sslist, jsonGraph } = dataResponse;
        setServicesList(sslist || []);
        if (jsonGraph && jsonGraph !== "") setJsonGraph(JSON.parse(jsonGraph));
      } else {
        if (errorMsg) {
          toastContent = (
            <CustomBodyToast title={t("FORM_ERROR_ACTION")} msg={errorMsg} />
          );
        } else {
          toastContent = (
            <CustomBodyToast
              title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
              msg={null}
            />
          );
        }
      }
    } else {
      toastContent = (
        <CustomBodyToast
          title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
          msg={null}
        />
      );
    }

    if (toastContent) {
      toast.error(toastContent, {
        containerId: TOAST_CONTAINER_LAYOUT,
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setFetching(false);
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="tracking-services-dialog"
      className={classes.dialogContainer}
      maxWidth={false}
      key={"tracking_services_dialog"}
      id={"tracking_services_dialog"}
      PaperComponent={DraggableComponent}
    >
      {/* TITLE */}
      <DialogTitle id="tracking-services-dialog-title" style={{ padding: 8 }}>
        <div className={classes.dialogTitleContent}>
          <div className={classes.dialogTitleContentIcon}>
            <Icon
              className="fas fa-file-invoice"
              style={{ color: materialTheme.palette.content.mainColor }}
            />
            <Typography className={classes.dialogTitleContentText}>
              {formInstanceId}
            </Typography>
          </div>
          <div className={classes.dialogTitleContentIcon}>
            <IconButton onClick={getTrackingServicesList}>
              <Icon className="fas fa-sync" style={commonIconStyles} />
            </IconButton>
            <IconButton onClick={() => handleOpen(false)}>
              <Icon className="fas fa-times-circle" style={commonIconStyles} />
            </IconButton>
          </div>
        </div>
      </DialogTitle>

      {/* CONTENT */}
      <DialogContent
        style={{
          padding: 8,
        }}
        key={"tracking_services_dialog_content"}
      >
        <Tabs value={tab} onChange={handleTabChange} aria-label="tracking_tabs">
          <Tab label="Services" {...a11yProps(0)} />
          <Tab
            label="Graph"
            {...a11yProps(1)}
            disabled={!jsonGraph || isEmpty(jsonGraph)}
          />
        </Tabs>
        <TabContainer value={tab} index={0}>
          <ServicesListTable
            servicesList={servicesList}
            fetching={fetching}
            totalDuration={totalDuration}
          />
        </TabContainer>
        <TabContainer value={tab} index={1}>
          <JsonGraphVisualizer jsonGraph={jsonGraph} />
        </TabContainer>
      </DialogContent>

      {/* BUTTONS ACTIONS */}
      <DialogActions>
        <Button color="primary" onClick={() => handleOpen(false)}>
          {t("FORM_BACK")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TrackingServicesDialog;
