import React, { useState, useMemo, useRef, useContext } from "react";
import * as _ from "lodash";
import { toast } from "react-toastify";
import { useTheme } from "@material-ui/core";
import BlockUi from "react-block-ui";
import { useTranslation } from "react-i18next";

import Table from "../table/MainTable";

import {
  callServerToSetValue,
  //controlHasError,
  extractErrorMessageFromResponse,
  getCompletePathGridItem,
  isLastRowAuto,
  isServerResponseValid,
  //makeErrorMapToSet,
  returnFocusToRef,
  transformFormStatusFromServer,
  valueHasChanged,
} from "../../../../util/UtilForm";
import {
  formatV1ColumnsToV3,
  getEmptyRowToAdd,
  handleDeleteRowByValue,
  notifyServerDeleteRows,
  notifyServerInitRow,
} from "../../../../util/UtilTable";
import { getMockDataTable } from "../../../../util/MockData";
import { AuthContext } from "../../../../core/providers/AuthContext";
import {
  REST_HEADERS,
  TOKEN_HEADER,
  TOKEN_INIT,
  TOAST_CONTAINER_FORM_CONTAINER,
  HTTP_STATUS_UNAUTHORIZED,
  HTTP_STATUS_NOT_FOUND,
  TOAST_TYPE_ERROR,
  NEW_GRID_DISTRIBUTION_SOLUTIONS,
} from "../../../../util/Constants";
import CustomBodyToast from "../../../toast/CustomBodyToast";
import { TableContainer } from "./GridTableCustomContainer";
import { useBlockingToast } from "../../../../core/hooks/useBlockingToast";
import { defaultColumn } from "../cells";
import { useFeatureOnSolutions } from "../../../../core/hooks/useFeatureOnSolutions";

// Be sure to pass our updateDataTable and the skipPageReset option

export default function GridTable(props) {
  const {
    columns,
    formData,
    handleFormDataChange,
    name,
    formContext,
    dataInTable,
    schema,
  } = props;
  const {
    section,
    block,
    formInstanceId,
    operation,
    enableAddRow,
    enableDeleteRow,
    //syncErrorMap,
    //errorMap,
    toggleIsSettingValueToServer,
    shadowStatus,
    updateShadowStatus,
    colDisableDeleteRow,
    showSearchBarRow,
    maxRows,
    denyAddRow,
    disableMassiveGridLoad,
    hideRadioButtonsGrid,
    formSchema,
    isBlockDisabledByWizard,
  } = formContext;

  const titleBlock = schema?.title;

  const theme = useTheme();
  const isNewGridDistributionEnabled = useFeatureOnSolutions(
    NEW_GRID_DISTRIBUTION_SOLUTIONS
  );

  //COLUMNS
  const v3Columns = useMemo(() => formatV1ColumnsToV3(columns), [columns]);

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [isSettingValueInServer, setIsSettingValueInServer] = useState(false);
  const toastId = useRef(null);

  const { auth, logout } = useContext(AuthContext);

  const { renderBlockingToast, BlockingToastModal } = useBlockingToast();

  const REQUEST_HEADERS = {
    ...REST_HEADERS,
    [TOKEN_HEADER]: `${TOKEN_INIT}${auth.token}`,
  };

  const { t } = useTranslation();

  //#region [Important]
  //SENSIBLE, all cell types call this method to update data table BE CAREFUL
  //ONLY UPDATE 1 VALUE OF THE ROW
  const updateDataTable = async (
    rowIndex,
    columnId,
    value,
    cellRef,
    column
  ) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    const lineNumber = rowIndex + 1; // line in server starts at 1

    //E.G: Section: G, Block: G1, ColumnId: countryID, Name(GridName): paises
    const completeId = getCompletePathGridItem(
      section,
      block,
      `_${columnId}`,
      name,
      column
    );

    if (
      valueHasChanged(shadowStatus, {
        newValue: value,
        field: completeId,
        line: lineNumber,
        list: `#${section?.name}.${block}.${name}`,
      })
    ) {
      toggleIsSettingValueToServer(true);
      setIsSettingValueInServer(true);

      //Await for server response
      const response = await callServerToSetValue(
        formInstanceId,
        completeId,
        value,
        lineNumber,
        REQUEST_HEADERS
      );

      //processServerResponse(response, columnId, true);
      processServerResponse(
        response,
        columnId,
        false,
        cellRef,
        //lineNumber,
        //completeId
      );
    }
  };
  //#endregion

  //Add new empty row at end
  async function handleAddNewRow(forceAddRow) {
    const lastRow =
      dataInTable && dataInTable.length > 0
        ? formData[name][dataInTable.length - 1]
        : null;
    const lastRowIsAuto = isLastRowAuto(lastRow);
    if (_.isNull(lastRow) || !lastRowIsAuto || forceAddRow) {
      //Make DInitRow
      const param = {
        formInstanceId,
        procesarDependencias: true,
        tableId: `#${section.name}.${block}.${name}`,
        rowNumber: dataInTable.length + 1,
      };
      //Call server
      const response = await notifyServerInitRow(param, REQUEST_HEADERS);

      //Process response
      processServerResponse(
        response,
        `${name}_${dataInTable.length}`,
        false,
        null
      );
    }
  }

  //In charge of construct and analyze new form data in base of server response
  function processServerResponse(response, columnId, initRow, cellRef) {
    let statusToShadow = null;
    if (response && response.status === HTTP_STATUS_UNAUTHORIZED) {
      logout(true);
    }

    let toastContent = null;
    if (response && response.status === HTTP_STATUS_NOT_FOUND) {
      toastContent = (
        <CustomBodyToast
          title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
          msg={null}
        />
      );
    }
    const isValid = isServerResponseValid(response);

    //Process responsex
    if (isValid) {
      //Destr response
      //fStatus is from SetValue
      //formStatus is from InitRow
      const { fstatus, formStatus } = response;
      const formStatusToSetAndTransform = !_.isNil(fstatus)
        ? fstatus
        : formStatus;

      const transformedFormStatus = transformFormStatusFromServer(
        formStatusToSetAndTransform
      );
      statusToShadow = formStatusToSetAndTransform;

      //Check if control has errors to clear them
      //checkToClearErrorMsg(controlErrorKey);

      if (enableAddRow && initRow) {
        //const lastRow = transformedFormStatus[name][dataInTable.length - 1];
        //const lastRowIsAuto = isLastRowAuto(lastRow);
        const lastRowIsAuto = true;
        if (!lastRowIsAuto) {
          //Make empty row to add new data
          const emptyRowToAdd = getEmptyRowToAdd(v3Columns, dataInTable.length);
          const gridItems = transformedFormStatus[name];
          const newGridItems =
            gridItems && gridItems.length > 0
              ? [...gridItems, emptyRowToAdd]
              : [emptyRowToAdd];
          const newFormDataToSet = {
            ...formData,
            ...transformedFormStatus,
            [name]: newGridItems,
          };
          //First change the form data
          handleFormDataChange(newFormDataToSet);
          //Then notifyServer the new row created automatically
          handleAddNewRow(true);
        } else {
          //handleFormDataChange({ ...formData, ...transformedFormStatus }); TRUST IN SERVER
          handleFormDataChange(transformedFormStatus);
        }
      } else {
        //handleFormDataChange({ ...formData, ...transformedFormStatus }); TRUST IN SERVER
        handleFormDataChange(transformedFormStatus);
      }
    } else if (
      !_.isNil(response) &&
      ((!_.isNil(response.error) && response.error === true) ||
        (!_.isNil(response.ok) && response.ok === false))
    ) {
      if (!_.isNil(response.fstatus) || !_.isNil(response.formStatus)) {
        const { fstatus, formStatus } = response;
        const formStatusToSetAndTransform = !_.isNil(fstatus)
          ? fstatus
          : formStatus;
        const transformedFormStatus = transformFormStatusFromServer(
          formStatusToSetAndTransform
        );
        handleFormDataChange(transformedFormStatus);
        statusToShadow = formStatusToSetAndTransform;
      }

      //Extract error message if there is one
      const toastText = extractErrorMessageFromResponse(response);

      toastContent = {
        title: toastText,
      };

      if (cellRef) returnFocusToRef(cellRef);
    }

    //Throw toast
    if (toastContent !== null) {
      renderBlockingToast({
        type: TOAST_TYPE_ERROR,
        ...toastContent,
      });
    }

    if (statusToShadow !== null) {
      updateShadowStatus(statusToShadow);
    }

    setIsSettingValueInServer(false);
    toggleIsSettingValueToServer(false);
  }

  //Check in error map if some error is assosciated with completeIdControl
  //If it has, remove toast and sync map
  // const checkToClearErrorMsg = (controlErrorKey) => {
  //   const controlWithError = controlHasError(errorMap, controlErrorKey);
  //   if (controlWithError) {
  //     let newMap = _.clone(errorMap);
  //     newMap = newMap.filter(function (obj) {
  //       return obj.key !== controlErrorKey;
  //     });
  //     //Set new error map without control ones
  //     syncErrorMap(newMap);
  //     //Dismiss possible notifications of this control
  //     toast.dismiss(controlErrorKey);
  //   }
  // };

  //#region [Important]
  // SERVER CALL: delete rows
  async function handleDeleteSelectedRows(selectedRows, selectedFlatRows) {
    if (!_.isNil(selectedRows) && !_.isEmpty(selectedRows)) {
      let rows = Object.keys(selectedRows).map(Number);
      let rowsLine = [];
      for (let i = 0; i < rows.length; i++) {
        const hasToCheck =
          !_.isNil(colDisableDeleteRow) &&
          _.isString(colDisableDeleteRow) &&
          !_.isNil(selectedFlatRows) &&
          !_.isEmpty(selectedFlatRows);
        if (hasToCheck) {
          const disabledRowByValue = handleDeleteRowByValue({
            row: selectedFlatRows[i],
            colDisableDeleteRow,
          });
          if (!disabledRowByValue) {
            const rowIndex = rows[i];
            rowsLine.push(rowIndex + 1);
          }
        } else {
          const rowIndex = rows[i];
          rowsLine.push(rowIndex + 1);
        }
      }

      //WAITING FOR SERVER
      //Call server to notifiy and server will return all data of the new table
      const param = {
        deletedRows: rowsLine,
        tableId: `#${section.name}.${block}.${name}`,
        formInstanceId,
      };
      const serverResponse = await notifyServerDeleteRows(
        param,
        REQUEST_HEADERS
      );
      const { ok, msg, formStatus, status } = serverResponse;

      let toastContent = null;
      if (status === HTTP_STATUS_UNAUTHORIZED) {
        logout(true);
      } else if (status === HTTP_STATUS_NOT_FOUND) {
        toastContent = (
          <CustomBodyToast
            title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
            msg={null}
          />
        );
      } else {
        if (ok) {
          const transformedFormStatus =
            transformFormStatusFromServer(formStatus);
          updateShadowStatus(formStatus);

          //Server handling status
          handleFormDataChange(transformedFormStatus);
        } else {
          toastContent = (
            <CustomBodyToast title={t("FORM_ERROR_ACTION")} msg={msg} />
          );
        }
      }

      if (toastContent !== null) {
        //Throw toast
        toastId.current = toast.error(toastContent, {
          containerId: TOAST_CONTAINER_FORM_CONTAINER,
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }
  //#endregion

  // MOCK function to add 10K rows
  function handleChargeMockData() {
    const newData = getMockDataTable(10 * 1000);
    //Syncro inner and global state
    handleDataSyncro(newData);
  }

  //In charge of syncro global state
  function handleDataSyncro(newData) {
    //Syncro with global form state
    let formDataSnapshot = _.clone(formData);
    const newFormData = Object.assign(formDataSnapshot, { [name]: newData });
    handleFormDataChange(newFormData);
  }

  return (
    <TableContainer
      theme={theme}
      isNewGridDistributionEnabled={isNewGridDistributionEnabled}
    >
      <BlockingToastModal />
      <div
        style={{
          width: "100%",
          height: "-webkit-fill-available",
          paddingBottom: 5,
        }}
      >
        <BlockUi
          id={`block_${name}`}
          key={`block_${name}`}
          style={{
            width: "100%",
            height: "-webkit-fill-available",
          }}
          tag="div"
          blocking={isSettingValueInServer}
          //renderChildren={false}
        >
          <Table
            id={`table_${name}`}
            key={`table_${name}`}
            columns={v3Columns}
            data={dataInTable}
            updateDataTable={updateDataTable}
            skipPageReset={skipPageReset}
            defaultColumn={defaultColumn}
            operation={operation}
            formContext={formContext}
            handleDeleteSelectedRows={handleDeleteSelectedRows}
            handleAddNewRow={handleAddNewRow}
            enableAddRow={enableAddRow}
            enableDeleteRow={enableDeleteRow}
            handleChargeMockData={handleChargeMockData}
            //handleFormDataChange={handleFormDataChange}
            processServerResponse={processServerResponse}
            isSettingValueInServer={isSettingValueInServer}
            colDisableDeleteRow={colDisableDeleteRow}
            showSearchBarRow={showSearchBarRow}
            titleBlock={titleBlock}
            maxRows={maxRows}
            shadowStatus={shadowStatus}
            denyAddRow={denyAddRow}
            disableMassiveGridLoad={disableMassiveGridLoad}
            hideRadioButtonsGrid={hideRadioButtonsGrid}
            formSchema={formSchema}
            formData={formData}
            isBlockDisabledByWizard={isBlockDisabledByWizard}
          />
        </BlockUi>
      </div>
    </TableContainer>
  );
}
